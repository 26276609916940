import apiHelper from "../../utils/apiHelpers";
import NavBar from "../common/NavBar";
import Footer from "../common/Footer";
import React from "react";
import formatter from "../../utils/formatter";
import CustomSelect from "../common/CustomSelect";
import LoadingOverlay from "react-loading-overlay";
import { withAlert } from "react-alert";
import Loader from "./../common/Loader";
import SelectDatesYears from "../common/filters/SelectDatesYears";
import Swal from "sweetalert2";
import "../common/SweetAlert.css";
import apiHelpers from "../../utils/apiHelpers";
import SelectOrators from "../common/filters/SelectOrators";
import qs from "qs";
import UserProfessionals from "./UserProfessionals";

class UsersDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: null,
            inputErrors: {
                name: "",
                email: "",
                isOrator: "",
                sex: "",
                age: null,
                province: null,
            },
            loading: false,
            isNew: props.match.params.id === "new",
            userId: props.match.params.id,
            user: {
                name: "",
                document_type: "DNI",
                sex: null,
                document_number: "",
                age: null,
                email: "",
                phone: null,
                mobile_phone: null,
                password: "",
                organization: "",
                prefix_name: "",
                province: null,
                created_at: "",
                professional_id: null,
            },
            user_role: {
                user_id: "",
                role_id: "",
            },
            professionals: [],
            queryParams: qs.parse(this.props.location.search, { ignoreQueryPrefix: true }),
        };
    }

    componentDidMount() {
        if (!this.state.isNew) {
            apiHelper
                .getUser(this.state.userId)
                .then((response) => {
                    this.setState({
                        user: response.data,
                    });
                })
                .catch((error) => {
                    console.log(error);
                });

            let roleFilters = {
                filter: {
                    where: {
                        and: [
                            {
                                user_id: {
                                    like: `%${this.state.userId}%`,
                                },
                            },
                        ],
                    },
                    limit: 10,
                },
            };            
            apiHelper
                .getUserRoles(roleFilters)
                .then((response) => {
                    this.setState({
                        user_role: response.data[0],
                    });
                })
                .catch((error) => {
                    console.log(error);
                });

            let userProfessionalsFilters = {
                filter: {
                    where: {
                        and: [
                            {
                                user_id: this.state.userId,
                            },
                        ],
                    },
                    limit: 10,
                },
            };
            apiHelper
                .getUserProfessionals(userProfessionalsFilters)
                .then((response) => {
                    this.setState({
                        professionals: response.data,
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            this.setState({
                user_role: {
                    user_id: "", 
                    role_id: this.state.queryParams.role,
                }
            });
        }
    }

    validateUser(user) {
        let inputErrors = {};
        let regName = /^[a-zA-ZÀ-ÿ\s]{1,40}$/;
        let regEmail = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]{1,40}$/;
        let regInitials = /^[a-zA-Z]{2}$/;
        let theAge = parseInt(this.state.user.age);

        if (!user.email.trim()) {
            inputErrors.email = "El campo 'email' no puede estar vacio";
        } else if (!regEmail.test(user.email.trim())) {
            inputErrors.email = "El campo 'email' es incorrecto";
        }
        if (!user.name.trim()) {
            inputErrors.name = "El campo 'nombre' no puede estar vacio";
        } else if (
            (apiHelper.isAdmin() &&
                !this.state.user_role.role_id === "ATTENDEE") ||
            apiHelper.isProfessional() || apiHelper.isMedicalProfessional()
        ) {
            if (!regInitials.test(user.name.trim())) {
                inputErrors.name =
                    "Solo puedes ingresar iniciales de nombre y apellido";
            } else if (apiHelper.isCoordinator()) {
                if (!regName.test(user.name.trim())) {
                    inputErrors.name = "Solo puedes ingresar letras y espacios";
                } else if (!regName.test(user.name.trim())) {
                    inputErrors.name = "Solo puedes ingresar letras y espacios";
                }
            }
        }
        if ((apiHelper.isProfessional() || apiHelper.isMedicalProfessional()) && user.sex === null) {
            inputErrors.sex = "Debes seleccionar una opción";
        }

        if ((apiHelper.isProfessional() || apiHelper.isMedicalProfessional()) && theAge === null) {
            inputErrors.age = "Debes Indicar una edad";
        }

        if ((apiHelper.isProfessional() || apiHelper.isMedicalProfessional()) && isNaN(theAge)) {
            inputErrors.age = "La edad ingresada es Incorrecta";
        }
        if ((apiHelper.isProfessional() || apiHelper.isMedicalProfessional()) && theAge < 1) {
            inputErrors.age = "El usuario no debe ser menor al año";
        }
        if ((apiHelper.isProfessional() || apiHelper.isMedicalProfessional()) && theAge > 110) {
            inputErrors.age = "El usuario no debe ser mayor de 110 años";
        }

        if ((apiHelper.isProfessional() || apiHelper.isMedicalProfessional()) && theAge > 110) {
            inputErrors.age = "El usuario no debe ser mayor de 110 años";
        }

        if (this.state.user.age === null) {
            this.state.user.age = 0;
        }

        if (apiHelper.isAdmin() || apiHelper.isMedicalCoordinator()) {
            if (
                (this.state.user_role.role_id === "PROFESSIONAL" || 
                this.state.user_role.role_id === "MEDICAL_PROFESSIONAL") &&
                user.province === null
            ) {
                inputErrors.province = "Debe seleccionar una provincia";
            } else if (
                this.state.user_role.role_id === "ATTENDEE" &&
                user.province === null
            ) {
                inputErrors.province = "Debe seleccionar una provincia";
            } else if (
                this.state.user_role.role_id === "ATTENDEE" &&
                (this.state.professionals.length === 0)
            ) {
                inputErrors.selectProfessional = "Debe asignar a un profesional ";
            } else if (
                (this.state.user_role.role_id === "COORDINATOR" ||
                    this.state.user_role.role_id === "MEDICAL_COORDINATOR") &&
                user.province === null
            ) {
                user.province = "NO REQUERIDA";
            }
        }

        if (
            (apiHelper.isCoordinator() && user.province === null) ||
            (apiHelper.isProfessional() && user.province === null) || 
            (apiHelper.isMedicalProfessional() && user.province === null)
        ) {
            inputErrors.province = "Debe seleccionar una provincia";
        }

        return inputErrors;
    }

    validateRole(userRole) {
        let inputErrors = {};
        if (apiHelper.isCoordinator() && userRole.role_id === "") {
            inputErrors.isOrator = "Debes seleccionar una opción";
        }
        return inputErrors;
    }

    getCreationDate() {
        let creationDay = Date.now();
        creationDay = formatter.formatDateForAPI(creationDay);

        return creationDay;
    }

    handleChange(event) {
        const target = event.target;
        let value = target.type === "checkbox" ? target.checked : target.value;
        value =
            target.type === "react-datetime"
                ? formatter.formatDateForAPI(target.value)
                : target.value;
        value =
            target.type === "number" ? parseInt(target.value) : target.value;
        const name = target.name;

        // Special cases
        if (name === "user_role") {
            let userRole = this.state.user_role;
            userRole.role_id = value;
            this.setState(prevState => {
                return({
                    ...prevState,
                    user_role: userRole,
                });
            });
            return;
        }

        if (name === "professionals") {
            let professionals = value;
            this.setState(prevState => {
                return({
                    ...prevState,
                    professionals,
                });
            });
            return;
        }

        // Default case (user State)
        const user = this.state.user;
        user[name] = value;

        this.setState(prevState => {
            return({
                ...prevState,
                user: user,
            });
        });
    }

    async deleteUser() {        
        let text = '';
        if(this.state.user_role.role_id === "PROFESSIONAL" || this.state.user_role.role_id === "MEDICAL_PROFESSIONAL") {
            text = 'Este usuario es un profesional. Los pacientes asociados al mismo también serán eliminados.'
        }

        Swal.fire({
            title: `¿Desea eliminar al usuario?`,
            text: text,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, eliminar'
        }).then( async (result) => {
            if (result.isConfirmed) {                
                await apiHelper.deleteUser(this.state.userId);
    
                // Medical Coordinator special redirect case
                let pushHashLocation = "";
                if(apiHelper.isMedicalCoordinator()) {
                    pushHashLocation = "#" + this.state.user_role.role_id;
                }
                if(apiHelper.isCoordinator()) {
                    pushHashLocation = "#" + "Invitable";
                }
                this.props.history.push("/users" + pushHashLocation);
                
                Swal.fire(
                    'Eliminado',
                    'El usuario ha sido eliminado.',
                    'success'
                )                
            }
        })
    }

    async submitUser(event) {
        event.preventDefault();
        const validation = this.validateUser(this.state.user);

        if (Object.getOwnPropertyNames(validation).length) {
            this.setState({ inputErrors: validation });
            return;
        }

        const validationRole = this.validateRole(this.state.user_role);
        if (Object.getOwnPropertyNames(validationRole).length) {
            this.setState({ inputErrors: validationRole });
            return;
        }

        try {
            this.setState({ loading: true });
            //If requests already exists, update it. If not, create it
            if (this.state.isNew) {
                const theUser = {
                    ...this.state.user,
                    created_at: this.getCreationDate(),
                    age: parseInt(this.state.user.age),
                    professionals: this.state.professionals.filter(pro => pro.professional_id !== null),
                };
                let resp = await apiHelper.postUser(theUser);

                //After updating the user, update the related role
                await apiHelper.postUserRole({
                    user_id: resp.data.id,
                    role_id: this.state.user_role.role_id,
                });

                if (apiHelper.postUser) {
                    if (apiHelper.isProfessional() || apiHelper.isMedicalProfessional()) {
                        Swal.fire({
                            position: "center",
                            backdrop: false,
                            icon: "success",
                            titleText: "Paciente creado con éxito",
                            showConfirmButton: false,
                            timer: 2000,
                            customClass: {
                                titleText: "customText",
                            },
                        });
                    } else {
                        Swal.fire({
                            position: "center",
                            backdrop: false,
                            icon: "success",
                            titleText: "Usuario creado con éxito",
                            showConfirmButton: false,
                            timer: 2000,
                            customClass: {
                                titleText: "customText",
                            },
                        });
                    }
                }
            } else {
                await apiHelper.patchUser(
                    this.state.userId, 
                    {
                        ...this.state.user, 
                        professionals: this.state.professionals.filter(pro => pro.professional_id !== null),
                    }
                );
                //After updating the user, update the related role
                await apiHelper.patchUserRole(
                    this.state.user_role.id,
                    this.state.user_role
                );

                if (apiHelper.patchUser) {
                    if (apiHelper.isProfessional() || apiHelper.isMedicalProfessional()) {
                        Swal.fire({
                            position: "center",
                            backdrop: false,
                            icon: "success",
                            titleText: "Paciente actualizado con éxito",
                            showConfirmButton: false,
                            timer: 2000,
                            customClass: {
                                titleText: "customText",
                            },
                        });
                    } else {
                        Swal.fire({
                            position: "center",
                            backdrop: false,
                            icon: "success",
                            titleText: "Usuario actualizado con éxito",
                            showConfirmButton: false,
                            timer: 2000,
                            customClass: {
                                titleText: "customText",
                            },
                        });
                    }
                }
            }
            this.setState({ loading: false });

            // Medical Coordinator special redirect case
            let pushHashLocation = "";
            if(apiHelper.isMedicalCoordinator()) {
                pushHashLocation = "#" + this.state.user_role.role_id;
            }
            if(apiHelper.isCoordinator()) {
                    pushHashLocation = "#" + "Invitable";
                }

            this.props.history.push("/users" + pushHashLocation);
        } catch (error) {
            this.setState({ loading: false });
            const statusCode = error.response.status;
            let errorMessage;
            if (statusCode === 422) {
                errorMessage = "Datos invalidos o incompletos";
            } else {
                errorMessage = error.response.data.error.message;
            }
            this.setState({
                errorMessage: errorMessage,
            });
        }
    }

    render() {
        let alert;
        let title = "Crear Paciente";
        let deleteButton = false;
        let name = null;
        let role = null;
        let selectProfessional = null;
        let document = null;
        let edited = "";
        let password = null;
        let organization = null;
        let isOrator = null;
        let prefix_name = " ";
        let numberId = null;
        let age = null;
        let sex = null;

        const nameOptions = [
            { value: " ", label: "N/A" },
            { value: "Dr.", label: "Dr." },
            { value: "Dra.", label: "Dra." },
            { value: "Cdor.", label: "Cdor." },
            { value: "Cdra.", label: "Cdra." },
            { value: "Mg.", label: "Mg." },
            { value: "Lic.", label: "Lic." },
            { value: "Sr.", label: "Sr." },
            { value: "Sra.", label: "Sra." },
            { value: "Srta.", label: "Srta." },
        ];

        const nameOptionsProf = [
            { value: "Dr.", label: "Dr." },
            { value: "Dra.", label: "Dra." },
            { value: "Lic.", label: "Lic." },
            { value: "Mg.", label: "Mg." },
        ];

        const sexOptions = [
            { value: "F", label: "Femenino" },
            { value: "M", label: "Masculino" },
        ];

        const oratorOptions = [
            { value: "PROFESSIONAL", label: "Si" },
            { value: "ATTENDEE", label: "No" },
        ];

        const rolesOptions = [
            { value: "AUDITOR", label: "Auditor" },
            { value: "COORDINATOR", label: "Coordinador" },
            { value: "MEDICAL_COORDINATOR", label: "Coordinador Médico" },
            { value: "ATTENDEE", label: "Oyente paciente" },
            { value: "PROFESSIONAL", label: "Profesional" },     
            { value: "MEDICAL_PROFESSIONAL", label: "Profesional médico" },         
        ];

        const coordinatorRoles = ["PROFESSIONAL","MEDICAL_PROFESSIONAL"];
        const medicalCoordinatorRoles = ["ATTENDEE","MEDICAL_PROFESSIONAL"];

        const provinceOptions = [
            {
                value: "Ciudad Autónoma de Buenos Aires",
                label: "Ciudad Autónoma de Buenos Aires",
            },
            { value: "Buenos Aires", label: "Buenos Aires" },
            { value: "Catamarca", label: "Catamarca" },
            { value: "Chaco", label: "Chaco" },
            { value: "Chubut", label: "Chubut" },
            { value: "Córdoba", label: "Córdoba" },
            { value: "Corrientes", label: "Corrientes" },
            { value: "Entre Rios", label: "Entre Rios" },
            { value: "Formosa", label: "Formosa" },
            { value: "Jujuy", label: "Jujuy" },
            { value: "La Pampa", label: "La Pampa" },
            { value: "La Rioja", label: "La Rioja" },
            { value: "Mendoza", label: "Mendoza" },
            { value: "Misiones", label: "Misiones" },
            { value: "Neuquén", label: "Neuquén" },
            { value: "Rio Negro", label: "Rio Negro" },
            { value: "Salta", label: "Salta" },
            { value: "San Juan", label: "San Juan" },
            { value: "San Luis", label: "San Luis" },
            { value: "Santa Cruz", label: "Santa Cruz" },
            { value: "Santa Fe", label: "Santa Fe" },
            { value: "Santiago del Estero", label: "Santiago del Estero" },
            { value: "Tierra del Fuego", label: "Tierra del Fuego" },
            { value: "Tucumán", label: "Tucumán" },
        ];

        const PATIENT_NAME_LENGTH = 2;
        let theId = this.state.userId.toString();
        if (theId.length === 1 || this.state.isNew) {
            numberId = "000";
        } else if (theId.length === 2) {
            numberId = "00";
        } else if (theId.length === 3) {
            numberId = "0";
        }

        // ERROR MSG
        if (this.state.errorMessage) {
            alert = (
                <>
                    <div className="col-8  alert alert-warning text-center ">
                        {this.state.errorMessage}
                    </div>
                </>
            );
        }

        // Existing users
        if(!this.state.isNew){
            if (
                    this.state.user_role.role_id === "ATTENDEE" &&
                    this.state.user.name.length <= PATIENT_NAME_LENGTH
            ) {
                //Existing attendee
                title =
                    numberId +
                    this.state.userId + " " +
                    this.state.user.name.toUpperCase();
                deleteButton = (
                    <button
                        className="btn  btn-dark float-right"
                        type="button"
                        onClick={this.deleteUser.bind(this)}
                    >
                        Eliminar <span className="fa fa-trash-alt"></span>
                    </button>
                );
                edited = !this.state.user.created_at
                    ? ""
                    : "Creado el " +
                    formatter.formatDate(this.state.user.created_at);
            } else {
                title = this.state.user.name;
                deleteButton = (
                    <button
                        className="btn btn-dark pull-right"
                        type="button"
                        onClick={this.deleteUser.bind(this)}
                    >
                        Eliminar <span className="fa fa-trash-alt"></span>
                    </button>
                );
                edited = !this.state.user.created_at
                    ? ""
                    : "Creado el " +
                    formatter.formatDate(this.state.user.created_at);
            }
        }

        let today = Date.now();

        // New user
        if (this.state.isNew) {
            if (apiHelper.isProfessional() || apiHelper.isMedicalProfessional() || this.state.queryParams.role === "ATTENDEE") {
                title = "Crear Paciente";
            } else {
                // for admin, medical coordinator and coordinator
                title = "Crear Usuario";
            }

            edited = "Creado Hoy: " + formatter.formatDate(today);
            if (apiHelper.isAdmin()) {
                //let valor={value:this.state.user.name};
                name = (
                    <div className="col-8">
                        <div className="form-group">
                            <label htmlFor="name">Nombre completo</label>
                            <input
                                type="text"
                                name="name"
                                className="form-control"
                                placeholder="No definido"
                                value={this.state.user.name}
                                onChange={this.handleChange.bind(this)}
                                required
                                maxLength="40"
                            />
                        </div>
                        {this.state.inputErrors.name && (
                            <p className="inputError">
                                {this.state.inputErrors.name}
                            </p>
                        )}
                    </div>
                );
                document = (
                    <div className="col-8">
                        <div className="form-group">
                            <label htmlFor="document_number">Matrícula</label>
                            <input
                                type="text"
                                name="document_number"
                                className="form-control"
                                value={this.state.user.document_number || ""}
                                placeholder="No definido"
                                onChange={this.handleChange.bind(this)}
                            />
                        </div>
                    </div>
                );
                password = (
                    <div className="col-8">
                        <div className="form-group">
                            <label htmlFor="last_name">Contraseña</label>
                            <input
                                type="password"
                                name="password"
                                className="form-control"
                                placeholder="No definido"
                                value={this.state.user.password}
                                onChange={this.handleChange.bind(this)}
                                required
                            />
                        </div>
                    </div>
                );
                role = (
                    <div className="col-8">
                        <div className="form-group">
                            <label htmlFor="user_role">Rol</label>
                            <CustomSelect
                                name="user_role"
                                value={this.state.user_role.role_id || this.state.queryParams.role}
                                isDisabled={this.state.queryParams.role}
                                onChange={this.handleChange.bind(this)}
                                options={rolesOptions}
                                placeholder="No definido"
                            />
                        </div>
                    </div>
                );
                age = null;
                sex = null;
            } else {
                name = //profesional
                    (
                        <div className="col-8">
                            <div className="form-group">
                                <label htmlFor="name">
                                    Iniciales de nombre y apellido
                                </label>
                                <input
                                    type="text"
                                    name="name"
                                    className="form-control"
                                    placeholder="No definido"
                                    value={this.state.user.name.toUpperCase()}
                                    onChange={this.handleChange.bind(this)}
                                    maxLength="2"
                                    minLength="2"
                                    required
                                />
                            </div>
                            {this.state.inputErrors.name && (
                                <p className="inputError">
                                    {this.state.inputErrors.name}
                                </p>
                            )}
                        </div>
                    );
                document = null;
                password = null;

                age = (
                    <div className="col-md-6  col-xs-6">
                        <div className="form-group">
                            <label htmlFor="age">Edad</label>
                            <input
                                type="number"
                                name="age"
                                className="form-control"
                                placeholder="No definido"
                                value={this.state.user.age}
                                onChange={this.handleChange.bind(this)}
                                required
                                maxLength="3"
                            />
                        </div>
                        {this.state.inputErrors.age && (
                            <p className="inputError">
                                {this.state.inputErrors.age}
                            </p>
                        )}
                    </div>
                );

                sex = (
                    <div className="col-md-6  col-xs-6">
                        <div className="form-group">
                            <label htmlFor="sex">Sexo</label>
                            <CustomSelect
                                name="sex"
                                value={this.state.user.sex}
                                onChange={this.handleChange.bind(this)}
                                options={sexOptions}
                                placeholder="No definido"
                                required
                            />
                        </div>
                        {this.state.inputErrors.sex && (
                            <p className="inputError">
                                {this.state.inputErrors.sex}
                            </p>
                        )}
                    </div>
                );
            }
        }

        if (apiHelper.isAdmin() || (apiHelper.isMedicalCoordinator() && this.state.queryParams.role !== "ATTENDEE")) {
            name = (
                <div className="col-md-8">
                    <div className="form-group">
                        <label htmlFor="name">Nombre y Apellido</label>
                        <input
                            type="text"
                            name="name"
                            className="form-control"
                            placeholder="No definido"
                            value={this.state.user.name}
                            onChange={this.handleChange.bind(this)}
                            required
                            maxLength="40"
                        />
                    </div>
                    {this.state.inputErrors.name && (
                        <p className="inputError">
                            {this.state.inputErrors.name}
                        </p>
                    )}
                </div>
            );
            document = (
                <div className="col-md-8">
                    <div className="form-group">
                        <label htmlFor="document_number">Matrícula</label>
                        <input
                            type="text"
                            name="document_number"
                            className="form-control"
                            value={this.state.user.document_number || ""}
                            placeholder="No definido"
                            onChange={this.handleChange.bind(this)}
                        />
                    </div>
                </div>
            );
            role = (
                <div className="col-md-8">
                    <div className="form-group">
                        <label htmlFor="user_role">Rol</label>
                        <CustomSelect
                            name="user_role"
                            value={this.state.user_role.role_id || this.state.queryParams.role}
                            isDisabled={this.state.queryParams.role || apiHelper.isMedicalCoordinator()}
                            onChange={this.handleChange.bind(this)}
                            options={rolesOptions.filter(option => {
                                if(apiHelper.isAdmin()) {
                                    return true;
                                } else {
                                    return medicalCoordinatorRoles.includes(option.value);
                                }
                            })}
                            placeholder="No definido"
                        />
                    </div>
                </div>
            );
            password = (
                <div className="col-md-8">
                    <div className="form-group">
                        <label htmlFor="last_name">Contraseña</label>
                        <input
                            type="password"
                            name="password"
                            className="form-control"
                            placeholder="No definido"
                            value={this.state.user.password}
                            onChange={this.handleChange.bind(this)}
                            required
                        />
                    </div>
                </div>
            );
            age = null;
            sex = null;
        }

        if (apiHelper.isCoordinator()) {
            prefix_name = (
                <div className="col-2">
                    <div className="form-group">
                        <label htmlFor="prefix_name">Titulo</label>
                        <CustomSelect
                            name="prefix_name"
                            value={this.state.user.prefix_name || null}
                            onChange={this.handleChange.bind(this)}
                            options={nameOptions}
                            placeholder="No definido"
                        />
                    </div>
                </div>
            );
            name = (
                <div className="col-6">
                    <div className="form-group">
                        <label htmlFor="name">Nombre y Apellido</label>
                        <input
                            type="text"
                            name="name"
                            className="form-control"
                            placeholder="No definido"
                            value={this.state.user.name}
                            onChange={this.handleChange.bind(this)}
                            required
                            maxLength="40"
                        />
                    </div>
                </div>
            );
            document = (
                <div className="col-8">
                    <div className="form-group">
                        <label htmlFor="document_number">
                            Matrícula / Cargo
                        </label>
                        <input
                            type="text"
                            name="document_number"
                            className="form-control"
                            value={this.state.user.document_number || ""}
                            placeholder="No definido"
                            onChange={this.handleChange.bind(this)}
                        />
                    </div>
                </div>
            );
            organization = (
                <div className="col-8">
                    <div className="form-group">
                        <label htmlFor="organization">
                            Organizacion/Empresa
                        </label>
                        <input
                            type="text"
                            name="organization"
                            className="form-control"
                            value={this.state.user.organization || ""}
                            placeholder="No definido"
                            onChange={this.handleChange.bind(this)}
                            required
                        />
                    </div>
                </div>
            );

            role = (
                <div className="col-md-8">
                    <div className="form-group">
                        <label htmlFor="user_role">Rol</label>
                        <CustomSelect
                            name="user_role"
                            value={this.state.user_role.role_id || this.state.queryParams.role}
                            isDisabled={this.state.queryParams.role}
                            onChange={this.handleChange.bind(this)}
                            options={rolesOptions.filter(option => {
                                return coordinatorRoles.includes(option.value);
                            })}
                            placeholder="No definido"
                        />
                    </div>
                </div>
            );

            /*isOrator = (
                <div className="col-8">
                    <div className="form-group">
                        <label htmlFor="user_role">Es Orador</label>
                        <span className="ml-2 must ">*Campo Obligatorio</span>

                        <CustomSelect
                            name="user_role"
                            value={this.state.user_role.role_id || this.state.queryParams.role}
                            isDisabled={this.state.queryParams.role}
                            onChange={this.handleChange.bind(this)}
                            options={oratorOptions}
                            placeholder="Si/No"
                            required
                        />
                    </div>
                    {this.state.inputErrors.isOrator && (
                        <p className="inputError">
                            {this.state.inputErrors.isOrator}
                        </p>
                    )}
                </div>
            );*/

            age = null;
            password = null;
            sex = null;
            isOrator = null;
        }

        /*********************
        ** ROLE SELECTOR CASES
        **********************/
        //COORDINATOR
        if (
            apiHelper.isAdmin() &&
            (this.state.user_role.role_id === "COORDINATOR" ||
                this.state.user_role.role_id === "MEDICAL_COORDINATOR")
        ) {
            document = (
                <div className="col-8">
                    <div className="form-group">
                        <label htmlFor="document_number">Cargo</label>
                        <input
                            type="text"
                            name="document_number"
                            className="form-control"
                            value={this.state.user.document_number || ""}
                            placeholder="No definido"
                            onChange={this.handleChange.bind(this)}
                        />
                    </div>
                </div>
            );
        }
        //PROFESSIONAL
        if (
            (apiHelper.isAdmin() || apiHelper.isMedicalCoordinator()) &&
            (this.state.user_role.role_id === "PROFESSIONAL" || this.state.user_role.role_id === "MEDICAL_PROFESSIONAL")
        ) {
            role = (
                <div className="row col-12">
                    <div className="col-2 m-0 p-0"></div>
                    <div className="col-md-8 m-0 p-0">
                        <div className="form-group">
                            <label htmlFor="user_role">Rol</label>
                            <CustomSelect
                                name="user_role"
                                value={this.state.user_role.role_id || this.state.queryParams.role}
                                isDisabled={this.state.queryParams.role || apiHelper.isMedicalCoordinator()}
                                onChange={this.handleChange.bind(this)}
                                options={rolesOptions.filter(option => {
                                    if(apiHelper.isAdmin()) {
                                        return true;
                                    } else {
                                        return medicalCoordinatorRoles.includes(option.value);
                                    }
                                })}
                                placeholder="No definido"
                            />
                        </div>
                    </div>
                </div>
            );
            name = (
                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="name">Nombre y Apellido</label>
                        <input
                            type="text"
                            name="name"
                            className="form-control"
                            placeholder="No definido"
                            value={this.state.user.name}
                            onChange={this.handleChange.bind(this)}
                            required
                            maxLength="40"
                        />
                    </div>
                </div>
            );
            prefix_name = (
                <div className="col-md-2">
                    <div className="form-group">
                        <label htmlFor="prefix_name">Titulo</label>
                        <CustomSelect
                            name="prefix_name"
                            value={this.state.user.prefix_name || null}
                            onChange={this.handleChange.bind(this)}
                            options={nameOptionsProf}
                            placeholder="No definido"
                        />
                    </div>
                </div>
            );
        }
        //ATTENDEE
        if (
            (apiHelper.isAdmin() || apiHelper.isMedicalCoordinator()) &&
            (this.state.user_role.role_id === "ATTENDEE"
            || this.state.queryParams.role === "ATTENDEE")
        ) {
            //desde admin o coordinador- oyente
            
            selectProfessional = (
                <div className="col-8">
                    <div className="form-group">
                        <label htmlFor="user_role">Profesional asignado</label>
                        <span className="ml-2 must ">*Campo Obligatorio</span>
                        <UserProfessionals
                            onChange={this.handleChange.bind(this)}
                            name="professionals"
                            value={this.state.professionals || []}
                            placeholder="Seleccione profesional médico"
                        />
                    </div>
                    {this.state.inputErrors.selectProfessional && (
                        <p className="inputError">
                            {this.state.inputErrors.selectProfessional}
                        </p>
                    )}
                </div>
            );

            name = (
                <div className="col-8">
                    <div className="form-group">
                        <label htmlFor="name">
                            Iniciales de nombre y apellido
                        </label>
                        <input
                            type="text"
                            name="name"
                            className="form-control"
                            placeholder="No definido"
                            value={this.state.user.name.toUpperCase()}
                            onChange={this.handleChange.bind(this)}
                            minLength="2"
                            maxLength="2"
                            required
                        />
                    </div>
                    {this.state.inputErrors.name && (
                        <p className="inputError">
                            {this.state.inputErrors.name}
                        </p>
                    )}
                </div>
            );

            document = null;
            password = null;
        }

        if (apiHelper.isProfessional() || apiHelper.isMedicalProfessional()) {
            age = (
                <div className="col-md-6 col-xs-6">
                    <div className="form-group">
                        <label htmlFor="age">Edad</label>
                        <input
                            type="number"
                            name="age"
                            className="form-control"
                            placeholder="No definido"
                            value={this.state.user.age}
                            onChange={this.handleChange.bind(this)}
                            maxLength="3"
                            required
                        />
                    </div>
                    {this.state.inputErrors.age && (
                        <p className="inputError">
                            {this.state.inputErrors.age}
                        </p>
                    )}
                </div>
            );
            sex = (
                <div className="col-md-6 col-xs-6">
                    <div className="form-group">
                        <label htmlFor="sex">Sexo</label>
                        <CustomSelect
                            name="sex"
                            value={this.state.user.sex}
                            onChange={this.handleChange.bind(this)}
                            options={sexOptions}
                            placeholder="No definido"
                            required
                        />
                    </div>
                    {this.state.inputErrors.sex && (
                        <p className="inputError">
                            {this.state.inputErrors.sex}
                        </p>
                    )}
                </div>
            );
        } else {
            age = null;
            sex = null;
        }

        return (
            <div className="d-flex flex-column h-100">
                {/*<NavBar />*/}
                <div className="container">
                    {this.state.loading == true ? (
                        <Loader />
                    ) : (
                        <>
                            <div className="row  align-self-center justify-content-center">
                                <div className="col-5 align-self-start">
                                    <h3 className="text-oceandrive mb-1">
                                        <i className="fas fa-user"></i> {title}
                                    </h3>
                                    <div className="subtitle-section">
                                        <span>{edited}</span>
                                    </div>
                                </div>

                                <div className="col-5 mt-4 align-self-center">
                                    {deleteButton}
                                </div>
                            </div>
                            <form onSubmit={this.submitUser.bind(this)}>
                                <div className="row justify-content-center mt-3">
                                    {role}
                                    {selectProfessional}

                                    {prefix_name}
                                    {name}

                                    <div className="row col-8 m-0 p-0">
                                        {age}
                                        {sex}
                                    </div>
                                    {apiHelper.isAdmin() &&
                                    (this.state.user_role.role_id ===
                                        "COORDINATOR" ||
                                        this.state.user_role.role_id ===
                                            "MEDICAL_COORDINATOR") ? null : (
                                        <div className="col-8">
                                            <div className="form-group">
                                                <label htmlFor="province">
                                                    Provincia
                                                </label>
                                                <CustomSelect
                                                    name="province"
                                                    value={
                                                        this.state.user.province
                                                    }
                                                    onChange={this.handleChange.bind(
                                                        this
                                                    )}
                                                    options={provinceOptions}
                                                    placeholder="No definido"
                                                />
                                            </div>
                                            {this.state.inputErrors
                                                .province && (
                                                <p className="inputError">
                                                    {
                                                        this.state.inputErrors
                                                            .province
                                                    }
                                                </p>
                                            )}
                                        </div>
                                    )}

                                    <div className="col-8">
                                        <div className="form-group">
                                            <label htmlFor="email">Email</label>
                                            <input
                                                type="email"
                                                name="email"
                                                className="form-control"
                                                placeholder="No definido"
                                                value={this.state.user.email}
                                                onChange={this.handleChange.bind(
                                                    this
                                                )}
                                                maxLength="40"
                                                required
                                            />
                                        </div>
                                        {this.state.inputErrors.email && (
                                            <p className="inputError">
                                                {this.state.inputErrors.email}
                                            </p>
                                        )}
                                    </div>
                                    {document}
                                    {password}
                                    {organization}
                                    {isOrator}

                                    {alert}
                                </div>

                                <div className="text-center">
                                    <button
                                        className="btn btn-primary btn-vhumanize mt-3 mb-5"
                                        type="submit"
                                    >
                                        Guardar <i className="fas fa-save"></i>
                                    </button>
                                </div>
                            </form>
                        </>
                    )}
                </div>
                <Footer />
            </div>
        );
    }
}

export default withAlert()(UsersDetail);
