import React from "react";
import apiHelper from '../../utils/apiHelpers';
import NavBar from "../common/NavBar";
import Footer from "../common/Footer";
import './Profile.css';
import ChangePasswordContainer from "./ChangePasswordContainer";

class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            document_type: '',
            document_number: '',
            phone: '',
            mobile_phone: '',
            email: '',
        }
    }

    componentDidMount() {
        apiHelper.getCurrentUser()
            .then(response => {
                this.setState({...this.state, ...response.data});
            })
            .catch(error => {
                console.log(error);
            });
    }

    render() {
        return (
            <div className="d-flex flex-column h-100">
                  {/*<NavBar/>*/}

                <div className="container pb-5 marginTopResp">
                    <div className="page-header text-center">
                        <h3 className="mt-2 text-oceandrive"> <span>{this.state.name}</span></h3>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-5">
                            <div className="form-group">
                                <label htmlFor="firstName">Nombre completo</label>
                                <input type="text" className="form-control" id="firstName"
                                       value={this.state.name} disabled="disabled" placeholder="No definido"/>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <input type="email" className="form-control" id="email"
                                       value={this.state.email} disabled="disabled" placeholder="No definido"/>
                            </div>
                        </div>
                    </div>

                    <ChangePasswordContainer history={this.props.history}/>
                </div>

                <Footer/>
            </div>
        )
    }
}
;


export default Profile;