import React, { useEffect, useState } from 'react';
import {
  pdf,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Link,
  Font,
} from '@react-pdf/renderer';
import moment, { max } from 'moment';
import apiHelpers from '../apiHelpers';
import { react_pdf_styles } from './exportPdfStyles';

// This is not a component!

// Styles!!
const styles = StyleSheet.create(react_pdf_styles);

const getUsers = async (appointmentUserArray) => {
  const userIds = appointmentUserArray.map( usr => usr.user_id );
  const usersResp = await (Promise.all(userIds.map(id => apiHelpers.getUser(id))));
  const users = usersResp.map(resp => resp.data);
  return users;
}

export const pdfTemplate = (
  props
) => {
  const { appointment, orators, attendees, screenshot } = props;

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Membrete */}
        <View style={styles.header} fixed>
          <View style={styles.headerBox}>
            <View style={styles.headerBoxTop}>
              <Text style={styles.headerText}>REPORTE DE EVENTO</Text>              
              <Image src={`${process.env.PUBLIC_URL+"/img/logotype.png"}`} style={styles.headerLogo}/>
              <Text style={styles.headerText}> {`Fecha: ${moment().format('DD/MM/YYYY')}`} </Text>
            </View>
            <View style={styles.headerBoxBottom}>              
            </View>
          </View>
        </View>

        {/* Patient Data */}
        <View style={styles.patientSection}>
          <Text style={styles.appointmentDataTitle}>
            {`Tema de charla: `}
            <Text style={styles.appointmentData}>{`${appointment.description}`}</Text>
          </Text>

          <Text style={styles.appointmentDataTitle}>
            {`Fecha de charla: `}
            <Text style={styles.appointmentData}>{`${moment(appointment.appointment_date).format(
              'DD/MM/YYYY',
            )}`}</Text>
          </Text>

          <Text style={styles.appointmentDataTitle}>
            {`Desde: `}
            <Text style={styles.appointmentData}>{`${appointment.scheduled_start_time}hs`}</Text>
          </Text>
          
          <Text style={styles.appointmentDataTitle}>
            {`Hasta: `}
            <Text style={styles.appointmentData}>{`${appointment.scheduled_end_time}hs`}</Text>
          </Text>
          
          <Text style={styles.appointmentDataTitle}>
            {`Link de Invitados: `}
            <Text style={styles.meeting_url}>{`${appointment.join_url}`}</Text>
          </Text>

          <Text style={styles.appointmentDataTitle}>
            {`Contraseña: `}
            <Text style={styles.appointmentData}>{`${appointment.meeting_password}`}</Text>
          </Text>
        </View>

        {/* RESULTS TABLE */}
        <View style={styles.section}>

          {/* ORADORES */}
          <Text style={styles.examTitle} key={`Orators`}>
            Oradores:{' '}
          </Text>,
          {
            orators.map((orator, i) => {
              return (
                <Text style={styles.tableText}>
                  {`${i+1}. ${orator.prefix_name  || ''} ${orator.name}`}
                </Text>
              )
            })
          }

          {/* INVITADOS */}
          <Text style={styles.examTitle} key={`Orators`}>
            Invitados:{' '}
          </Text>,
          {
            attendees.map((attendee,i) => {

              return (
                <Text style={styles.tableText}>
                  {`${i+1}. ${attendee.prefix_name || ''} ${attendee.name.length > 2 ? attendee.name : attendee.name.toUpperCase()}`}
                </Text>
              )
            })
          }
        </View>

        {/* SCREENSHOT */}
        {screenshot ? (
          <View style={styles.section} break>
            <Text style={styles.sectionTitle}>Captura de pantalla</Text>
            <Image key="screenshot" src={screenshot} />
          </View>
        ) : (
          <></>
        )}

        {/* FOOTER */}        
        <View style={styles.footer} fixed>
          <View style={styles.footerBox}>
            <Text
              style={styles.pageNumber}
              render={({ pageNumber, totalPages }) =>
                `${pageNumber} / ${totalPages}`
              }
              fixed
            >
            </Text>
          </View>
        </View>

      </Page>
    </Document>
  );
};

const getScreenshot = async (appointmentId) => {
  return apiHelpers.downloadAppointmentScreenshot(appointmentId)
  .then((response) => {
    console.log("scrsht res",response);
    var urlCreator = window.URL;
    var imageUrl = urlCreator.createObjectURL(response.data);
    console.log("imageUrl", imageUrl);
    return imageUrl;  
  })
  .catch(e => {
    return null;
  });
}

export const generatePDFDocument = async (
  appointment
) => {
  let orators = await getUsers(appointment.orators);
  let attendees = await getUsers(appointment.attendees);
  let screenshot = await getScreenshot(appointment.id);
  const blob = await pdf(
    pdfTemplate(
     {appointment, orators, attendees, screenshot}
    ),
  ).toBlob();

  let fileURL = window.URL.createObjectURL(blob);
  let newWindow = window.open(fileURL);
  newWindow.print();
};
