import React from "react";

export default () => {
    return (
        <footer className="footer mt-auto">
            <div className="container">
                <div className="row text-center">
                        <div className="col-md-4">
                        </div>
                    <div className="col-md-4 mt-3">
                            <span>Made with <span className="text-red">♥</span> by Elig</span>
                    </div>
                    <div className="col-md-4 mt-1">
                    </div>
                </div>
            </div>
        </footer>
    )
}