import React from "react";
import PropTypes from "prop-types";
import SelectUserEmails from "../common/filters/SelectUserEmails";
import SelectUserNames from "../common/filters/SelectUserNames";
import apiHelpers from "../../utils/apiHelpers";

export default class UsersFilters extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.getInitialState();
    }

    getRoleFilter() {
        const locationHash = this.props.location.hash.replace(/#/g,'').toUpperCase();
        switch (locationHash) {
            case "MEDICAL_PROFESSIONAL":
                return ["MEDICAL_PROFESSIONAL"];
                break;
            case "PROFESSIONAL":
              return ["PROFESSIONAL"];
              break;
            case "ATTENDEE":
              return ["ATTENDEE"];
              break;
    
            default:
              return null;
              break;
          }
    }

    getFiltersObject() {
        // For more information on how to build this object: https://loopback.io/doc/en/lb3/Where-filter.html
        return {
            where: {
                roles: this.getRoleFilter(),
                and: [
                    {
                        name: {
                            eq: this.state.name
                        }
                    }, {
                        email: {
                            eq: this.state.email
                        }
                    }
                ]
            }
        }
    }

    getInitialState() {
        return {
            name: null,
            email: null
        }
    }

    onChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({[name]: value});
    }

    clearFilters() {
        this.setState(this.getInitialState(), () => {
            this.props.onChangeFilters(this.getFiltersObject());
        });
    }

    onFiltersSubmit() {
        this.props.onChangeFilters(this.getFiltersObject());
    }

    render() {
        
        
        return (
            <div className="row">
                <div className="col-lg-3 col-md-12 col-xs-12 mb-3">
                    <SelectUserEmails name="email" placeholder="Email"
                                      value={this.state.email}
                                      onChange={this.onChange.bind(this)}
                                      roleFilter={this.getRoleFilter()}
                                      required={false}/>
                </div>
                <div className="col-lg-3 col-md-12 col-xs-12 mb-3">
                    <SelectUserNames name="name" placeholder="Nombre"
                                     /*value={apiHelpers.isProfessional() ? this.state.name.toUpperCase() : this.state.name}*/
                                     value={this.state.name}
                                     onChange={this.onChange.bind(this)}
                                     roleFilter={this.getRoleFilter()}
                                     required={false}/>
                </div>
                <div className="col-lg-5 col-md-12 col-xs-12 mb-3 p-0">
                    <div className=" text-center">
                        <button type="button" className="btn btn-primary" onClick={this.onFiltersSubmit.bind(this)}>
                            Filtrar <i className="fas fa-search" style={{marginLeft: '3px'}}></i>
                        </button>
                        <button type="button" className="btn btn-warning" onClick={this.clearFilters.bind(this)}
                                style={{marginLeft: '4px'}}>
                            Reiniciar filtros <i className="fas fa-sync-alt"></i>
                        </button>
                    </div>
                </div>
            </div>
        );
    }
};
UsersFilters.propTypes = {
    onChangeFilters: PropTypes.func.isRequired
};