import React from "react";
import Select from "react-select";

export default class SelectYesNo extends React.Component {
    onChangeWrapper(selectedValue) {
        let event = {target: {}};
        event.target.name = this.props.name ? this.props.name : 'select-nameless';
        event.target.value = selectedValue.value;
        event.target.type = 'react-select';
        this.props.onChange(event);
    }

    render() {

        let value;
        this.props.value !== undefined ? value = this.props.options.find(x => x.value === this.props.value) : value = this.props.value;

        return (
        <Select name={this.props.name}
                value={value}
                onChange={this.onChangeWrapper.bind(this)}
                options={this.props.options}
                placeholder={this.props.placeholder}
                isDisabled={this.props.isDisabled}
              
                />
        )
    }
}