import 'react-datetime/css/react-datetime.css';
import React from "react";
import PropTypes from 'prop-types';
import Datetime from 'react-datetime'; //https://www.npmjs.com/package/react-datetime
import moment from 'moment';
import 'moment/locale/es';

export default class SelectDatesYears extends React.Component {

    stringDateToDate(stringDate) {
        let dateComponents = stringDate.split("/");
        return new Date(dateComponents[2] + "/" + dateComponents[1] + "/" + dateComponents[0]);
    }

    calculateAge(birthday) {
        let ageDifMs = Date.now() - birthday.getTime();
        let ageDate = new Date(ageDifMs); // miliseconds from epoch
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    }

    onChangeWrapper(selectedValue) {
        if(typeof selectedValue !== 'object' && this.props.required) //If value is not a valid date return
            return;

        let event = {target: {}};

        event.target.name = this.props.name ? this.props.name : 'select-dates-nameless';
        event.target.value = moment(selectedValue).isValid() ? moment(selectedValue).format("YYYY-MM-DD") : null;
        event.target.type = 'react-datetime';

        this.props.onChange(event);
    }

    render() {
        let dateValue;
        if (!this.props.value && this.props.defaultDateToday) {
            dateValue = new Date();
        } else {
            dateValue = this.props.value ? moment(this.props.value).toDate() : null;
        }

        let selectDateHtml = <Datetime
            className={this.props.className}
            dateFormat={"DD/MM/YYYY"}
            timeFormat={false}
            closeOnSelect={true}
            inputProps={{
                'required': this.props.required,
                'placeholder': this.props.placeholder,
                'autoComplete': 'off',
                'name': this.props.name,
                'disabled': this.props.isDisabled,
                readOnly: true,
            }}
            name={this.props.name}
            onChange={this.onChangeWrapper.bind(this)}
            value={dateValue}
            viewMode="years"
            
        />;

        if (this.props.showYears && dateValue) {
            let yearsLabel = this.calculateAge(dateValue) + ' años';

            selectDateHtml = <div>
                {selectDateHtml}
                <div className="pl-3">{yearsLabel}</div>
            </div>;
        }

        return (
            selectDateHtml
        )
    }
}

SelectDatesYears.propTypes = {
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    defaultDateToday: PropTypes.bool,
    showYears: PropTypes.bool,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    isDisabled: PropTypes.bool
};