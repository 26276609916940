import React from "react";
import apiHelper from "../../../utils/apiHelpers";
import AsyncSelect from "react-select/async";
import PropTypes from "prop-types";
import { oratorEmails } from "../../../config/config";

export default class SelectOrators extends React.Component {
  constructor(props) {
    super(props);

    this.wto = null;

    this.state = {
      options: [],
    };
    this.getOptions = (input, callback) => {
      // if (!input || input.length == 0) return;

      clearTimeout(this.wto);
      this.wto = setTimeout(() => {
        let filter = [
          {name: {like: `%${input}%`}},        
        ];

        let filters = {
          filter: {
            where: {
              roles: ["PROFESSIONAL"],
              and: filter,
            },
            order: "name ASC",
            limit: 10,
          },
        };

        if (apiHelper.isAdmin()) {
          filters = {
            filter: {
              where: {
                roles: ["PROFESSIONAL", "MEDICAL_PROFESSIONAL"],
                and: filter,
              },
              limit: 10,
              order: "name ASC",
            },
          };
        }

        if (apiHelper.isCoordinator()) {
          filters = {
            filter: {
              where: {
                and: [...filter, {email: {inq: oratorEmails}}],
              },
              limit: 10,
              order: "name ASC",
            },
          };
        }

        if (apiHelper.isMedicalCoordinator()) {
          filters = {
          filter: {
            where: {
              roles: ["MEDICAL_PROFESSIONAL"],
              and: filter,
            },
            order: "name ASC",
            limit: 10,
          },
        };
       }

        apiHelper
          .getUsers(filters)
          .then((users) => {
            let usersForSelect = users.data.map((user) => {
              if(user.user_roles.some(role=>role.role_id === "PROFESSIONAL" || role.role_id === "MEDICAL_PROFESSIONAL")) {
                return {
                  value: user.id,
                  label: user.name,
                };
              }
            })
            .filter(usrOption=>{
              return this.props.addedValues ?
              !this.props.addedValues.some(usr => (usr.professional_id === usrOption.value || usr.user_id === usrOption.value))
              : true;
            });

            this.setState({
              options: usersForSelect,
            });
            callback(usersForSelect);
          })
          .catch(function (err) {
            callback(err, null);
          });
      }, 1000);
    };
  }

  onChangeWrapper(selectedValue) {
    let event = { target: {} };
    event.target.name = this.props.name
      ? this.props.name
      : "select-client-nameless";
    event.target.value = selectedValue ? selectedValue.value : '';
    event.target.type = "react-select";
    this.props.onChange(event);
  }

  getDefaultOptions(id) {
    if (!id) return;

    let filters = {
      filter: {
        where: {
          and: [
            {
              id: id,
            },
          ],
        },
      },
    };

    apiHelper
      .getUsers(filters)
      .then((users) => {
        let usersForSelect = users.data.map((user) => {
          return {
            value: user.id,
            label: user.name,
          };
        });

        this.setState({
          options: usersForSelect,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    let { value } = this.props;
    let userValue;

    if (value) {
      userValue = this.state.options.find((option) => option.value === value);
      if (!userValue) this.getDefaultOptions(value);
    }

    return (
      <AsyncSelect
        className={this.props.className}
        name={this.props.name}
        onChange={this.onChangeWrapper.bind(this)}
        value={userValue || ""} //Workaround to clear value with null. See https://github.com/JedWatson/react-select/issues/3066
        required={this.props.required}
        loadOptions={this.getOptions}
        placeholder={this.props.placeholder}
        menuContainerStyle={{ zIndex: 999 }}
        noOptionsMessage={() => "Ingrese un valor para buscar"}
        isClearable={this.props.isClearable !== undefined ? this.props.isClearable : this.props.required}
        defaultOptions={true}
        isDisabled={this.props.disabled}
      />
    );
  }
}
SelectOrators.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.number,
  isClearable: PropTypes.bool,
  disabled: PropTypes.bool,
};
