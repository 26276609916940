import React from "react";
import { Link } from "react-router-dom";
import "./Users.css";
import NavBar from "../common/NavBar";
import Footer from "../common/Footer";
import apiHelper from "../../utils/apiHelpers";
import Pagination from "../common/Pagination";
import UsersFilters from ".//UsersFilters";
import { withAlert } from "react-alert";
import Loader from "./../common/Loader";
import SelectOrators from "./../common/filters/SelectOrators";
class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      professional_id: null,
      filters: {where:{and:[]}},
      count: 0,
      pageSize: 10,
      currentPage: 1,
      loading: false,
    };
  }

  handleChangePage(pageOfItems) {
    this.setState({ currentPage: pageOfItems }, () => {
      this.getUsers();
    });
  }

  handleChangeFilters(filters) {
    this.setState({ filters: filters, currentPage: 1 }, () => {
      this.getUsers();
    });
  }

  handleChangeProfessional(event) {
    this.setState(
      {
        professional_id: event.target.value || null, 
        currentPage: 1,
      },
      () => {
        this.getUsers();
      }
    );
  }

  getNumberOfPages() {
    return Math.ceil(this.state.count / this.state.pageSize);
  }

  getCoordinatorFilters() {
    if(apiHelper.isMedicalCoordinator()) {
      let andArray = [...this.state.filters.where.and,];
      const locationHash = this.props.location.hash.replace(/#/g,'').toUpperCase();
      switch (locationHash) {
        case "MEDICAL_PROFESSIONAL":
          return {
            ...this.state.filters,
            where: {
              roles: ["MEDICAL_PROFESSIONAL"],
              and: andArray,
            },
          };
          break;

        case "ATTENDEE":
          return {
            ...this.state.filters,
            where: {
              roles: ["ATTENDEE"],
              professionals: [this.state.professional_id],
              and: andArray,
            },
          };
          break;

        default:
          return this.state.filters;
          break;
      }
    }

    if(apiHelper.isCoordinator()) {
      let andArray = [...this.state.filters.where.and,];
      const locationHash = this.props.location.hash.replace(/#/g,'').toUpperCase();
      switch (locationHash) {
        case "INVITABLE":
          return {
            ...this.state.filters,
            where: {
              and: [...andArray, {invitable: true}],
            },
          };

        default:
          return {
            ...this.state.filters,
            where: {
              and: andArray.length ? andArray : null,
            },
          };
      }
    }

    return this.state.filters;
  }

  async getUsers() {
    const {  currentPage, pageSize } = this.state;
    const filters = this.getCoordinatorFilters();

    try {
      // For more information on how to build this object: https://loopback.io/doc/en/lb3/Skip-filter.html
      this.setState({ loading: true });
      const filtersWithPaging = {
        filter: {
          ...filters,
          limit: pageSize,
          skip: (currentPage - 1) * pageSize,
        },
      };

      const [users, totalCount] = await Promise.all([
        await apiHelper.getUsers(filtersWithPaging),
        await apiHelper.getUsersCount({filter: {...filters}})
      ]);

      this.setState({ users: users.data, count: totalCount.data.count });
      this.setState({ loading: false });
    } catch (error) {
      this.setState({ loading: false });
      const statusCode = error.response.status;
      if (statusCode === 400 || statusCode === 422 || statusCode === 204) {
        this.props.alert.error("Error al cargar Usuarios");
      } else if (error.request) {
        this.props.alert.error("Sin respuesta del Servidor");
      } else {
        this.props.alert.error(error.message);
      }
    }
  }

  componentDidMount() {
    this.getUsers();
  }

  componentDidUpdate(prevProps, prevState) {
    // to trigger refresh when changing hash location
    if (prevProps.location.hash !== this.props.location.hash) {
      this.getUsers();
      this.setState(
        {
          currentPage: 1, 
          professional_id: null,
          filters: {where:{and:[]}},
        },
        () => {
          this.getUsers();
        }
      );
    }
  }

  render() {
    let nameColumn ="Nombre y Apellido";
    
    let createButton="Crear usuario";


    let title = (
      <h3 >
        <i className="fas fa-user"></i> Pacientes
      </h3>
    );

    if (apiHelper.isAdmin()) {
      title = (
        <h3 >
          <i className="fas fa-user"></i> Usuarios
        </h3>
      );
  
    }

    if (apiHelper.isCoordinator()) {
      createButton = "Crear profesional";
      if (
          this.props.location.hash.replace(/#/g, "").toUpperCase() ===
          "INVITABLE"
      ) {
          // Invitables
          title = (
              <h3 className="text-oceandrive">
                  <i className="fas fa-user"></i> Profesionales participantes
              </h3>
          );
      } else {
          title = (
              <h3 className="text-oceandrive">
                  <i className="fas fa-user-md"></i> Profesionales
              </h3>
          );
      }
    }

    if(apiHelper.isMedicalProfessional()){
      createButton="Crear paciente";
      nameColumn="Id Paciente";
    }

    if( apiHelper.isMedicalCoordinator() ) {
        if( this.props.location.hash.replace(/#/g,'').toUpperCase() === "MEDICAL_PROFESSIONAL" ) {
          title = (
            <h3 className="text-oceandrive">
              <i className="fas fa-user-md"></i> Profesionales
            </h3>
          );
          createButton="Crear profesional";
        } else {
          // Attendees
          title = (
            <h3 className="text-oceandrive">
              <i className="fas fa-user"></i> Pacientes
            </h3>
          );
          createButton="Crear paciente";
        }
    }

    let urlQuery = this.props.location.hash && apiHelper.isMedicalCoordinator() ? "?role=" + this.props.location.hash.replace(/#/g,'').toUpperCase() : "";

    return (
      <div className="d-flex flex-column h-100  dad" key={this.props.location.hash}>
    
          {/*<NavBar/>*/}
        <div className="container">
          <div className="title-container">
            {title}
            <div className="row">
          
            <div className="col-lg-9 col-md-12 col-sm-12 col-xs-12 m-0">
                <UsersFilters 
                  location={this.props.location}
                  onChangeFilters={this.handleChangeFilters.bind(this)} 
                />
            </div>        
            <div className="col-lg-2 col-md-12 col-sm-12 col-xs-12  text-center mb-3 ">
              <Link to={`${process.env.PUBLIC_URL}/users/new${urlQuery}`}>
                <button type="button" className="btn btn-success ">
                  <span className="glyphicon glyphicon-pencil nowrap">
                    {createButton} <i className="fas fa-plus-square"></i>
                  </span>
                </button>
              </Link>
            </div>
            
            {
              this.props.location.hash.replace(/#/g,'').toUpperCase() === "ATTENDEE" && 
              apiHelper.isMedicalCoordinator() ? 
              <div className="col-lg-11 col-md-11 col-sm-11 col-xs-11 mb-2">
                <SelectOrators 
                  name="user_id"
                  placeholder="Todos los profesionales"
                  value={this.state.professional_id || null}
                  onChange={this.handleChangeProfessional.bind(this)}
                  errorMessage={console.log()}
                  required={false}
                  isClearable={true}
                  disabled={false}
                />
              </div> 
              : 
              <></>
            }
            
          </div>
        </div>

          <div className="row">
            <div className="col-11 table-responsive">
              <table className="table table-bordered table-hover">
                {this.state.loading == true ? (
                  <Loader />
                ) : (
                  <>
                    <thead>
                      <tr>
                        <th scope="col">{nameColumn}</th>
                        <th scope="col">Email</th>
                        <th scope="col">Rol</th>
                        <th scope="col"style={{width:"5%"}}>Editar</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(() => {
                        if (!this.state.users.length) {
                          return (
                            <tr>
                              <td colSpan={6} rowSpan={this.state.pageSize} className="no_results">
                                No se encontraron resultados{" "}
                                <div>
                                  <i class="far fa-grin-beam-sweat"></i>
                                </div>
                              </td>
                            </tr>
                          );
                        }
                        return this.state.users.map((user) => {
                            const PATIENT_NAME_LENGTH = 2;
                            let numberId=""
                            let name=""
                            let theId = user.id.toString();
                                if (theId.length === 1 ) {
                                  numberId = "000";
                                } else if (theId.length === 2) {
                                  numberId = "00";
                                } else if (theId.length === 3) {
                                  numberId = "0";
                                }

                                if (
                                    user.user_roles[0].role.description === "Asistente" &&
                                    user.name.length <= PATIENT_NAME_LENGTH
                                ) {
                                  name=numberId + user.id + " " + user.name.toUpperCase();
                                } else {
                                  name=user.name
                                }
                          return (
                            
                            <tr key={user.id}>
                              <td>{name}</td>
                              <td>{user.email}</td>
                              <td>{user.user_roles[0].role.description}</td>
                              <td>
                                <div className="text-center">
                                  <Link to={`${process.env.PUBLIC_URL}/users/${user.id}`}>
                                    <button type="button" className="btn btn-default">
                                      <span className="fa fa-edit"></span>
                                    </button>
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          );
                        });
                      })()}
                    </tbody>
                  </>
                )}
              </table>
            </div>
          </div>
        </div>
        <div className="text-center">
          <Pagination
            onChangePage={this.handleChangePage.bind(this)}
            currentPage={this.state.currentPage}
            totalPages={this.getNumberOfPages()}
          />
        </div>
        <Footer />
      </div>
    );
  }
}

export default withAlert()(Users);
