import React from 'react';
import PropTypes from 'prop-types';
import './AppointmentUsers.css';
import SelectOrators from './filters/SelectOrators';
import apiHelpers from '../../utils/apiHelpers';

const ORATOR_MAX_COUNT = 2;

function AppointmentOratorRow(props) {

    return (
        <div className="appointment-user-row mt-2">
            <div className="">
                <SelectOrators name="user_id" placeholder="Seleccione Orador"
                                 value={props.item.user_id || null}
                                 addedValues={props.items}
                                 onChange={props.onUpdate}
                                 errorMessage={console.log()}
                                 required={true}
                                 disabled={props.disabled}
                                 />
            </div>
            <div className="">
                <button className="btn btn-minus" type="button" onClick={props.onRemove} disabled={props.disabled}>
                    <i className="fas fa-minus"></i></button>
            </div>
        </div>
    );

}

export default class AppointmentOrators extends React.Component {

    /*componentDidUpdate(prevProps) {
        if (prevProps.value != this.props.value) {
        }
    }*/

    createNewRow() {
        return {user_id: null};
    }

    handleAdd(index, event) {
       
        const appointmentOrators = this.props.value.slice();
        appointmentOrators.push(this.createNewRow());
        this.updateOnChange(appointmentOrators);
    }

    handleRemove(index, event) {
        const appointmentOrators = this.props.value.slice();
        const deletedOrator = appointmentOrators.splice(index, 1)[0];

        this.updateOnChange(appointmentOrators);
    }

    handleUpdate(index, event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let appointmentOrators = this.props.value.slice();
        appointmentOrators[index][name] = value;

        this.updateOnChange(appointmentOrators);
    }

    updateOnChange(appointmentOrators, args=null) {
        if (this.props.onChange) {
            let event = {target: {}};
            event.target.name = this.props.name || 'appointmentUsers-nameless';
            event.target.value = appointmentOrators.slice();
            event.target.type = 'elig-appointmentUsers';
            this.props.onChange(event, args);
        }
    }

    render() {
        const items = this.props.value || [];
        const selected= items.map(i=>i.user_id);
        const isAuditor = apiHelpers.isAuditor(); 
        
        let btnAdd=null;
        btnAdd=(    
        <div className="text-center">
            <button className="btn btn-success appointment-user-add" type="button"
                    onClick={this.handleAdd.bind(this)}>
                <i className="fas fa-plus"></i>
            </button>
        </div>);

        return (
            <div>
                <div>
                    {items.map((appointmentOrator, index) => {
                        let isFirst = index === 0;

                        return (
                            <AppointmentOratorRow
                                item={appointmentOrator}
                                items={items}
                                key={index}
                                index={index}
                                isFirst={isFirst}
                                onRemove={this.handleRemove.bind(this, index)}
                                onUpdate={this.handleUpdate.bind(this, index)}
                                disabled={isAuditor}
                            />
                        )
                    })}
                </div>
                { items.length < ORATOR_MAX_COUNT && !isAuditor ? btnAdd : null}
            
            </div>

        )
    }
}


AppointmentOrators.propTypes = {
    value: PropTypes.array,
    onChange: PropTypes.func,
    name: PropTypes.string
};