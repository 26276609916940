import apiHelper from "../../utils/apiHelpers";
import NavBar from "../common/NavBar";
import Footer from "../common/Footer";
import React, {useState} from "react";
import './Appointments.css';

import {render} from "react-dom"
import 'rsuite/dist/styles/rsuite-default.css';
import formatter from "../../utils/formatter";
import {Calendar, Badge, Popover, Whisper} from "rsuite/es/index";
import {IntlProvider} from 'rsuite';
import locale_es from "./locale_es";
import format from 'date-fns/format';
import es from 'date-fns/locale/es';
import {Link} from "react-router-dom";
import {CSVLink} from "react-csv";
import isMobile from "../../utils/isMobile";
import Loader from './../common/Loader';
import AsideBar from './../common/AsideBar';

var moment = require('moment');


const AlertUsers=({errorMessage})=>{
    return  <div className="alert alert-danger mt-3 ml-4"><i className="fas fa-exclamation-triangle"> </i>{"   "}{errorMessage}</div>;
}
export default class Appointments extends React.Component {

    download = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            appointments: [],
            currentUser:{},
            users: [],
            count: 0,
            CSVdata: "",
            errorMessage: false,
            loading:false,
            
        };
    }

    componentDidMount() {
        this.getAppointments();
        
    }
  
    

    async getAppointments() {
        try {
            this.setState({ loading: true });
            let appointments = (await apiHelper.getAppointments()).data;
            let users = (await apiHelper.getUsers({filter:{where: {roles: ["PROFESSIONAL", "MEDICAL_PROFESSIONAL"]}}})).data;
            let currentUser = (await apiHelper.getCurrentUser()).data;

            // Filter only appointments of current professional.
            if(apiHelper.isProfessional() || apiHelper.isMedicalProfessional()) {
                appointments = appointments.filter(
                    (appo) => {
                        return (
                            appo.orators.some(orator => {return orator.user_id === currentUser.id}) ||
                            appo.host_id === currentUser.id
                        )
                    }
                );
            }
            // ^
            
            this.setState({appointments: appointments , users:users, currentUser: currentUser});
            this.setState({ loading: false });
        } catch (e) {
            this.setState({ loading: false });
            console.log('Error loading appointments');
        }
    }

    async exportAppointments() {
        try {
            this.setState({ loading: true });
            const response = await apiHelper.getExportAppointments(this.state.query);
            this.setState({CSVdata: response.data}, () => {
                this.download.current.link.click()
            });
            this.setState({ loading: false });
        } catch (e) {
            this.setState({ loading: false });
            console.log('Error loading report');
        }
    }


    getTodayAppointments(date) {

        let formattedDate = formatter.formatDate(date);
        let appointments = this.state.appointments.filter(appointment => {
            let appointmentDate = formatter.formatDate(appointment.appointment_date);
            return appointmentDate == formattedDate;
        });

        let appointmentsArray = [];
      
        for (let appointment of appointments) {
            let orator= parseInt(appointment.orators.map(i=>i.user_id));
            let oratorsName = this.state.users.filter(user=>user.id===orator);
            let theName;
            let thePrefix;

            if(oratorsName.length === 0) { // Appointment with no Professional. SKIP this appointment.
                continue;
            }
            
            if(oratorsName[0]){
                theName=oratorsName[0].name || "" ;
                thePrefix=" - " + (oratorsName[0].prefix_name || "");
            };
            if(thePrefix===null || thePrefix === undefined){
                thePrefix="";
            }
            if(theName===null || theName === undefined){
                theName="";
            }
            appointmentsArray.push({id: appointment.id, title: appointment.description, prefix:thePrefix, orator:theName });
        }
        

        
 
        return appointmentsArray;
    }

    formatDate(data, formatStr) {
        return format(data, formatStr, {locale: es});
    }

    
  async getUsersCount(){
      
      try{
          let userCountResp = await apiHelper.getUsersCount();
          let userCount = userCountResp.data.count;

            if(apiHelper.isProfessional() || apiHelper.isMedicalProfessional() || apiHelper.isMedicalCoordinator()){
                if( userCount < 10 ){
               this.setState({errorMessage:true});
              return;
                }else{
                    this.props.history.push('/appointments/new'); 
                }
            }
            
            if(apiHelper.isAdmin()){
                if( userCount < 10 ){
                    this.setState({errorMessage:true});
                   return;
                     }else{
                         this.props.history.push('/appointments/new'); 
                     }
            }

            if (apiHelper.isCoordinator()){
                if( userCount < 20 ){
                    this.setState({errorMessage:true});
                    return;
            }else{
                this.props.history.push('/appointments/new'); 
            }
        }
            
        } catch (error) {
            
            return;
            
        }
    }
    
    
   

    renderCell(date) {
        const list = this.getTodayAppointments(date);
        const displayList = list.filter((item, index) => index < (isMobile ? 0 : 0));


        if (list.length) {
            const moreCount = list.length - displayList.length;
            const moreItem = (
                <li >
                    <Whisper
                        placement="right"
                        trigger="click"
                        speaker={
                            <Popover className="theSize">
                                {list.map((item, index) => {
                                    let event;
                                    if (apiHelper.isAdmin() || apiHelper.isAuditor() || apiHelper.isMedicalCoordinator() || formatter.isSameDayOrAfter(date)) {
                                        event =  <Link to={`${process.env.PUBLIC_URL}/appointments/${item.id}`}>
                                           <Badge/>
                                            {item.title.substring(0,40) + item.prefix +" "+ item.orator}
                                            </Link>
                                    } else {
                                        event = <div><Badge/>
                                            {item.title.substring(0,40) +  item.prefix +" "+ item.orator}
                                        </div> 
                                    }
                                    return (
                                        <p key={index}>
                                            {event}
                                        </p>)
                                })}
                            </Popover>
                        }
                    >
                        <a>{moreCount} {(list.length < 2 ? "Evento" : "Eventos")}</a>
                    </Whisper>
                </li>
            );
                                
            return (
                
                <div className="calendar-todo-list">
                    {displayList.map((item, index) => (
                        <p key={index}>
                            {this.state.appointments.map(appointment => {

                                if (item.id == appointment.id) {
                                    let event;
                                    if (apiHelper.isAdmin() || formatter.isSameDayOrAfter(date)) {
                                        event = <Link key={appointment.id}
                                                      to={`${process.env.PUBLIC_URL}/appointments/${appointment.id}`}>
                                            <Badge className="mr-1"/>
                                            {item.title.substring(0,40)}
                                        </Link>
                                    } else {
                                        event = <div><Badge className="mr-1"/>
                                            {item.title.substring(0,40) +  item.prefix +" "+ item.orator}
                                        </div>
                                    }
                                    return event;
                                }
                            })}

                        </p>
                    ))}
                    {moreCount ? moreItem : null}
                </div>
            );

            return null;
        }
    }

    render() {
     
        

        let title = <h3><i className="far fa-calendar-alt"></i> Agenda
        </h3>;

        


        if (apiHelper.isProfessional() || apiHelper.isMedicalProfessional() || apiHelper.isCoordinator() || apiHelper.isMedicalCoordinator()) {
            title =<>
                <div className="col-xl-6 col-md-6 col-xs-6 pl-4">
                <h3><i className="far fa-calendar-alt"></i> Agenda </h3>
                                    
                </div>
                <div className="col-xl-6 col-md-5 col-xs-6 align-self-center mt-2 ">
                    <button type="button" className="btn btn-success pull-right mr-5" onClick={this.getUsersCount.bind(this)}>
                        Agregar Evento <i className="fas fa-plus-square"></i>
                    </button>
                </div>
                
                {(apiHelper.isProfessional() || apiHelper.isMedicalProfessional() || apiHelper.isMedicalCoordinator()) && this.state.errorMessage ? <AlertUsers errorMessage="Debes tener al menos 10 pacientes cargados para agendar un nuevo evento."/> :null }
                {apiHelper.isCoordinator() && this.state.errorMessage ? <AlertUsers errorMessage="Debes tener al menos 20 participantes y un orador cargados para agendar un nuevo evento."/>:null }
         
            </>
           
        }
        
        if (apiHelper.isAdmin() || apiHelper.isAuditor()) {
            title = <>
                <div className="col-xl-6 col-md-6 col-xs-6 pl-4">
                    <h3><i className="far fa-calendar-alt"></i> Agenda </h3>                 
                </div>
                <div className="col-xl-6 col-md-5 col-xs-6 align-self-center mt-2 ">
                    <div className="section-buttons col-sm-12">
                        <CSVLink ref={this.download} data={this.state.CSVdata} filename="Reporte de agenda.csv"
                                className="hidden"></CSVLink>
                        <button type="button" className="btn btn-success pull-right some-air ml-3"
                                onClick={this.exportAppointments.bind(this)}>
                            Exportar a CSV <i className="fas fa-file-csv"></i>
                        </button>
                    </div>            
                </div>
                {apiHelper.isAdmin() && this.state.errorMessage ? <AlertUsers errorMessage="Debes tener más de 10 participantes y al menos un ORADOR cargados para agendar un nuevo evento"/>:null }
            </>
        }

        return (
            <div className="d-flex flex-column h-100 dad">
                
                {/*<NavBar/>*/}
                <div className="container pl-3 pr-3">
                    <div className="row">
                    {title}
                    <div className="col-12">

                    {this.state.loading ==true ? <Loader/> :<>
                    <IntlProvider locale={locale_es} formatDate={this.formatDate}>
                        <Calendar bordered renderCell={this.renderCell.bind(this)}/>
                    </IntlProvider>
                   </>}
                    </div>
                </div>
                </div>
              

                <Footer/>
            </div>
        );
    }
}


