import React from 'react';
import './Login.css';
import apiHelper from '../../utils/apiHelpers';
import {Link} from "react-router-dom";

class RecoverPassword extends React.Component {
    constructor() {
        super();
        this.state = {
            email: '',
            errorMessage: null
        };
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState(prevState => ({
            ...prevState,
            [name]: value,
        }));
    }

    async handleSubmit(event) {
        event.preventDefault();

        try {
            await apiHelper.resetPassword({
                email: this.state.email,
            });
            this.props.history.push('/login');
        } catch (error) {
            const statusCode = error.response.status;
            let errorMessage;

            if (statusCode === 401) {
                errorMessage = 'Email o contraseña invalidas';
            } else {
                errorMessage = error.message;
            }

            this.setState({
                errorMessage: errorMessage
            })
        }
    }

    render() {
        let alert;

        if (this.state.errorMessage) {
            alert = <div className="alert alert-warning">{this.state.errorMessage}</div>;
        }

        return (
            <div className="background-truck h-100">
                <div className="container">
                    <div className="wrapper fadeInDown">

                        <form className="form-signin" onSubmit={this.handleSubmit.bind(this)}>
                            <div className="fadeIn first">

                                <h3 className="text-center mt-2">¿Olvidaste tu contraseña?</h3>
                                <p>Ingresa tu direccion de Email para recuperarla</p>
                                <div className="panel-body">

                                    <div className="form-group">
                                        <div className="input-group">
                                            <input placeholder="Dirección de Email"
                                                   className="form-control"
                                                   name="email"
                                                   type="email"
                                                   value={this.state.email} onChange={this.handleChange.bind(this)}
                                                   required
                                                   autoFocus/>
                                        </div>
                                    </div>

                                    {alert}

                                    <button className="btn btn-primary btn-block"
                                            type="submit">
                                        Recuperar contraseña
                                    </button>
                                </div>

                                <hr/>

                                <Link to='/login'>Volver al Login</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}
;

export default RecoverPassword;