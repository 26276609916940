import React from "react";
import {Link} from "react-router-dom";
import apiHelper from '../../utils/apiHelpers';
import './NavBar.css'

export default class NavBar extends React.Component {

    constructor() {
        super();
        this.state = {
            user: {},

        };
    }

    componentDidMount() {
        apiHelper.getCurrentUser()
            .then(response => {
                this.setState({
                    user: response.data,
    
                });
            })
            .catch(error => {
                console.log(error);
            });
    }

    render() {

        let users = null;
        let role="";

        if(apiHelper.isAdmin()){
            role="Administrador"
        } else if(apiHelper.isCoordinator()){
            role="Coordinador"
        }else {
            role="Profesional"
        }
        if (apiHelper.isCoordinator()) {
            users = <Link className="dropdown-item" to={`${process.env.PUBLIC_URL}/users`}>
                <i className="fas fa-user fa-sm fa-fw mr-2 text-oceandrive"></i>Profesionales</Link>;
        }
        if (apiHelper.isMedicalProfessional()) {
            users = <Link className="dropdown-item" to={`${process.env.PUBLIC_URL}/users`}>
                <i className="fas fa-user fa-sm fa-fw mr-2 text-oceandrive"></i> Pacientes</Link>;
        }
        if (apiHelper.isAdmin()) {
            users = <Link className="dropdown-item" to={`${process.env.PUBLIC_URL}/users`}>
                <i className="fas fa-user fa-sm fa-fw mr-2 text-oceandrive"></i> Usuarios</Link>;
        }

        return (
            <div className="navbar-oceandrive">
                <nav className="navbar navbar-expand navbar-light bg-white topbar static-top shadow">
                    <Link  to={`${process.env.PUBLIC_URL}/Home`}><img className="logotype"
                        src={process.env.PUBLIC_URL + '/img/logotype.png'}
                        alt="vMedicalls logo"/></Link>
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item dropdown">
                            <span className="nav-link dropdown-toggle med" id="navbardrop" data-toggle="dropdown">
                                <img src={process.env.PUBLIC_URL + '/img/defaultuser.svg'}
                                     alt="User Dropdown"
                                     className="user-dropdown"/>
                            </span>
                            <div className="navbar-oceandrive dropdown-menu dropdown-menu-right">

                              
                                <span className="dropdown-item-text text-center">{this.state.user.name}</span>
                                <span className="dropdown-item-text text-center">{role}</span>
                               
                                
                                <hr/>
                                <Link className="dropdown-item" to={`${process.env.PUBLIC_URL}/appointments`}>
                                    <i className="fas fa-calendar fa-sm fa-fw mr-2 text-oceandrive"></i> Agenda</Link>
                                {users}
                                <hr/>
                                <Link className="dropdown-item" to={`${process.env.PUBLIC_URL}/profile`}>
                                    <i className="fas fa-user-circle fa-sm fa-fw mr-2 text-oceandrive"></i>
                                    Perfil</Link>
                                <Link to='/login' className="dropdown-item" onClick={apiHelper.logout}>
                                    <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-oceandrive"></i> Cerrar
                                    sesión
                                </Link>
                            </div>
                        </li>
                    </ul>
                </nav>
            </div>
        )
    }
}