import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import apiHelper from "../../utils/apiHelpers";
import moment from "moment";
import NavBar from "../common/NavBar";
import Footer from "../common/Footer";
import formatter from "../../utils/formatter";
import CustomSelect from "../common/CustomSelect";
import LoadingOverlay from "react-loading-overlay";
import { withAlert } from "react-alert";
import Loader from "./../common/Loader";
import SelectDatesYears from "../common/filters/SelectDatesYears";
import Swal from "sweetalert2";
import "../common/SweetAlert.css";
import AsideBar from "../common/AsideBar";

const nameOptions = [
    { value: " ", label: "N/A" },
    { value: "Dr.", label: "Dr." },
    { value: "Dra.", label: "Dra." },
    { value: "Cdor.", label: "Cdor." },
    { value: "Cdra.", label: "Cdra." },
    { value: "Mg.", label: "Mg." },
    { value: "Lic.", label: "Lic." },
    { value: "Sr.", label: "Sr." },
    { value: "Sra.", label: "Sra." },
    { value: "Srta.", label: "Srta." },
];

const nameOptionsProf = [
    { value: "Dr.", label: "Dr." },
    { value: "Dra.", label: "Dra." },
    { value: "Lic.", label: "Lic." },
    { value: "Mg.", label: "Mg." },
];

const sexOptions = [
    { value: "F", label: "Femenino" },
    { value: "M", label: "Masculino" },
];

const oratorOptions = [
    { value: "PROFESSIONAL", label: "Si" },
    { value: "ATTENDEE", label: "No" },
];

const rolesOptions = [
    { value: "COORDINATOR", label: "Coordinador" },
    { value: "PROFESSIONAL", label: "Profesional médico" },
    { value: "ATTENDEE", label: "Oyente paciente" },
    { value: "AUDITOR", label: "Auditor" },
];

const provinceOptions = [
    {
        value: "Ciudad Autónoma de Buenos Aires",
        label: "Ciudad Autónoma de Buenos Aires",
    },
    { value: "Buenos Aires", label: "Buenos Aires" },
    { value: "Catamarca", label: "Catamarca" },
    { value: "Chaco", label: "Chaco" },
    { value: "Chubut", label: "Chubut" },
    { value: "Córdoba", label: "Córdoba" },
    { value: "Corrientes", label: "Corrientes" },
    { value: "Entre Rios", label: "Entre Rios" },
    { value: "Formosa", label: "Formosa" },
    { value: "Jujuy", label: "Jujuy" },
    { value: "La Pampa", label: "La Pampa" },
    { value: "La Rioja", label: "La Rioja" },
    { value: "Mendoza", label: "Mendoza" },
    { value: "Misiones", label: "Misiones" },
    { value: "Neuquén", label: "Neuquén" },
    { value: "Rio Negro", label: "Rio Negro" },
    { value: "Salta", label: "Salta" },
    { value: "San Juan", label: "San Juan" },
    { value: "San Luis", label: "San Luis" },
    { value: "Santa Cruz", label: "Santa Cruz" },
    { value: "Santa Fe", label: "Santa Fe" },
    { value: "Santiago del Estero", label: "Santiago del Estero" },
    { value: "Tierra del Fuego", label: "Tierra del Fuego" },
    { value: "Tucumán", label: "Tucumán" },
];

const EventSignUp = (props) => {
    const history = useHistory();
    const [eventPassword, setEventPassword] = useState(null);
    const [loading, setLoading] = useState(false);
    const [userRole, setUserRole] = useState({
        user_id: "",
        role_id: "",
    });
    const [user, setUser] = useState({
        name: null,
        document_type: null,
        sex: null,
        document_number: null,
        age: null,
        email: null,
        phone: null,
        mobile_phone: null,
        password: null,
        organization: null,
        prefix_name: null,
        province: null,
        created_at: null,
    });
    const [inputErrors, setInputErrors] = useState({
        name: "",
        email: "",
        isOrator: "",
        sex: "",
        age: null,
        province: null,
    });
    const [errorMessage, setErrorMessage] = useState(null);
    const [appointments, setAppointments] = useState([]);
    const [selectedAppointment, setSelectedAppointment] = useState(null);

    const handleChange = (event) => {
        const target = event.target;
        let value = target.type === "checkbox" ? target.checked : target.value;
        value =
            target.type === "react-datetime"
                ? formatter.formatDateForAPI(target.value)
                : target.value;
        value =
            target.type === "number" ? parseInt(target.value) : target.value;
        const name = target.name;
        if (name === "user_role") {
            let user_role = userRole;
            user_role.role_id = value;
            setUserRole(user_role);
            return;
        }
        user[name] = value;

        setUser(user);
    };

    useEffect(() => {
        try {
            setLoading(true);
            apiHelper.getCurrentUser().then((response) => {
                setUser(response.data);
            });
            apiHelper
                .getPublicAppointments()
                .then((response) => {
                    const appoArray = response.data;
                    const futureAppo = appoArray.filter((appointment) => {
                        return moment(appointment.appointment_date).isAfter(
                            moment()
                        );
                    });
                    const sortedArray = futureAppo.sort(
                        (a, b) =>
                            moment(a.appointment_date).format("YYYYMMDD") -
                            moment(b.appointment_date).format("YYYYMMDD")
                    );
                    setAppointments(sortedArray);
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            setLoading(false);
            console.log("Error loading appointments");
        }
    }, []);

    const getCreationDate = () => {
        let creationDay = Date.now();
        creationDay = formatter.formatDateForAPI(creationDay);

        return creationDay;
    };

    const validateUser = (user) => {
        let inputErrors = {};
        let regName = /^[a-zA-ZÀ-ÿ\s]{1,40}$/;
        let regEmail = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]{1,40}$/;
        let regInitials = /^[a-zA-Z]{2}$/;

        if (!user.email.trim()) {
            inputErrors.email = "El campo 'email' no puede estar vacio";
        } else if (!regEmail.test(user.email.trim())) {
            inputErrors.email = "El campo 'email' es incorrecto";
        }

        if (user.province === null) {
            inputErrors.province = "Debe seleccionar una provincia";
        }

        return inputErrors;
    };

    const submitUser = async (event) => {
        event.preventDefault();
        const validation = validateUser(user);

        if (Object.getOwnPropertyNames(validation).length) {
            setInputErrors(validation);
            return;
        }

        try {
            setLoading(true);
            //If requests already exists, update it. If not, create it
            const theUser = {
                ...user,
                created_at: getCreationDate(),
                age: parseInt(user.age),
            };

            await apiHelper.postSignUpToEvent(
                selectedAppointment.id,
                theUser,
                eventPassword
            );

            Swal.fire({
                position: "center",
                backdrop: false,
                icon: "success",
                titleText: "Inscripción completa!",
                showConfirmButton: false,
                timer: 2000,
                customClass: {
                    titleText: "customText",
                },
            });

            setLoading(false);
            setSelectedAppointment(null);
            setEventPassword(null);
            props.history.push("/eventSignUp");
        } catch (error) {
            setLoading(false);
            let errorMessage = error.response.data.error.message;
            Swal.fire({
                position: "center",
                backdrop: false,
                icon: "error",
                titleText: errorMessage,
                showConfirmButton: true,
                timer: 7000,
                customClass: {
                    titleText: "customText",
                },
            });
            setErrorMessage(errorMessage);
            props.history.push("/eventSignUp");
        }
    };

    const onItemClick = async (appointment) => {
        let signUpPass = null;
        Swal.fire({
            title: appointment.description,
            input: "password",
            inputAttributes: {
                autocapitalize: "off",
                placeholder: "Código de invitación",
            },
            showCancelButton: true,
            cancelButtonText: "Atrás",
            confirmButtonText: "Continuar",
            showLoaderOnConfirm: true,
            preConfirm: (inputPassword) => {
                signUpPass = inputPassword;
                return apiHelper
                    .checkEventSignUpPassword(appointment.id, inputPassword)
                    .then((response) => {
                        if (response.data === false) {
                            throw new Error("Código de invitación incorrecto.");
                        }
                        return response;
                    })
                    .catch((error) => {
                        Swal.showValidationMessage(`${error}`);
                        setEventPassword(null);
                    });
            },
            allowOutsideClick: () => !Swal.isLoading(),
        }).then((result) => {
            if (result.isConfirmed) {
                //Redirect con la password
                setEventPassword(signUpPass);
                setSelectedAppointment(appointment);
            }
        });
    };

    const onBackClick = () => {
        setSelectedAppointment(null);
        setEventPassword(null);
    };

    let alert;
    let title = "Inscripción a eventos";

    return (
        <>
        {apiHelper.isAuthenticated() ? <AsideBar/> : <></>}
            <div className="d-flex flex-column h-100">
                <div className="container">
                    {loading === true ? (
                        <Loader />
                    ) : selectedAppointment === null ? (
                        <>
                            <div className="row align-self-center">
                                <div className="col-11 align-self-start">
                                    <h3 className="text-oceandrive mb-1">
                                        <i className="fas fa-file-signature mr-1"></i>
                                        {title}
                                    </h3>
                                </div>
                            </div>

                            <div className="container">
                                {appointments.length < 1 ? ( 
                                    // NO APPOINTMENTS AVAILABLE 
                                    <div class="alert alert-warning" role="alert">
                                        No hay eventos disponibles{" "} 
                                    </div>
                                ) : (
                                    // MAPPING APPOINTMENTS IN LIST
                                    <ol className="list-group list-group-numbered col-11">
                                        {appointments.map((appointment) => {
                                            return (
                                                <li
                                                    className="list-group-item d-flex justify-content-between align-items-start list-group-item-action"
                                                    key={appointment.id}
                                                    onClick={() => {
                                                        onItemClick(appointment);
                                                    }}
                                                >
                                                    <div className="ms-2 me-auto">
                                                        <div className="font-weight-bold">
                                                            {
                                                                appointment.description
                                                            }
                                                        </div>
                                                        {`${moment(
                                                            appointment.appointment_date
                                                        ).format(
                                                            "ddd DD MMMM YYYY"
                                                        )} (${
                                                            appointment.scheduled_start_time
                                                        } a ${
                                                            appointment.scheduled_end_time
                                                        })`}
                                                    </div>
                                                    <span className="badge bg-primary rounded-pill text-light">{``}</span>
                                                </li>
                                            );
                                        })}
                                    </ol>
                                )}
                            </div>
                        </>
                    ) : (
                        <></>
                    )}
                    {selectedAppointment ? (
                        <>
                            <div className="row align-self-center">
                                <div className="col-8 align-self-start">
                                    <h3 className="text-oceandrive mb-1">
                                        <i className="fas fa-file-signature mr-1"></i>
                                        {`Inscripción a: ${selectedAppointment.description}`}
                                    </h3>
                                </div>
                            </div>

                            <form onSubmit={submitUser}>
                                <div className="row justify-content-center mt-3">
                                    {/* {name} */}
                                    <div className="col-2">
                                        <div className="form-group">
                                            <label htmlFor="prefix_name">
                                                Título
                                            </label>
                                            <CustomSelect
                                                name="prefix_name"
                                                defaultValue={
                                                    user.prefix_name || null
                                                }
                                                onChange={handleChange}
                                                options={nameOptions}
                                                placeholder="No definido"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-6">
                                        <div className="form-group">
                                            <label htmlFor="name">
                                                Nombre y Apellido
                                            </label>
                                            <input
                                                type="text"
                                                name="name"
                                                className="form-control"
                                                placeholder="No definido"
                                                defaultValue={user.name}
                                                onChange={handleChange}
                                                required
                                                maxLength="40"
                                            />
                                        </div>
                                        {inputErrors.name && (
                                            <p className="inputError">
                                                {inputErrors.name}
                                            </p>
                                        )}
                                    </div>

                                    <div className="row col-8 m-0 p-0">
                                        {/* {birthDay}
                                    {age}
                                    {sex} */}
                                    </div>

                                    <div className="col-8">
                                        <div className="form-group">
                                            <label htmlFor="province">
                                                Provincia
                                            </label>
                                            <CustomSelect
                                                name="province"
                                                defaultValue={user.province}
                                                onChange={handleChange}
                                                options={provinceOptions}
                                                placeholder="No definido"
                                            />
                                        </div>
                                        {inputErrors.province && (
                                            <p className="inputError">
                                                {inputErrors.province}
                                            </p>
                                        )}
                                    </div>

                                    <div className="col-8">
                                        <div className="form-group">
                                            <label htmlFor="email">Email</label>
                                            <input
                                                type="email"
                                                name="email"
                                                className="form-control"
                                                placeholder="No definido"
                                                defaultValue={user.email}
                                                onChange={handleChange}
                                                maxLength="40"
                                                required
                                            />
                                        </div>
                                        {inputErrors.email && (
                                            <p className="inputError">
                                                {inputErrors.email}
                                            </p>
                                        )}
                                    </div>

                                    <div className="col-8">
                                        <div className="form-group">
                                            <label htmlFor="document_number">
                                                Matrícula/Cargo
                                            </label>
                                            <input
                                                type="text"
                                                name="document_number"
                                                className="form-control"
                                                defaultValue={user.document_number}
                                                placeholder="No definido"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-8">
                                        <div className="form-group">
                                            <label htmlFor="organization">
                                                Organizacion/Empresa
                                            </label>
                                            <input
                                                type="text"
                                                name="organization"
                                                className="form-control"
                                                defaultValue={user.organization}
                                                placeholder="No definido"
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>

                                    {alert}
                                </div>

                                <div className="row justify-content-around mt-2">
                                    <div className="text-center">
                                        <button
                                            className="btn btn-danger btn-vhumanize mt-3 mb-5"
                                            onClick={onBackClick}
                                        >
                                            Atrás
                                        </button>
                                    </div>

                                    <div className="text-center">
                                        <button
                                            className="btn btn-primary btn-vhumanize mt-3 mb-5"
                                            type="submit"
                                        >
                                            Guardar <i className="fas fa-save"></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </>
                    ) : (
                        <></>
                    )}
                </div>
                <Footer />
            </div>
        </>
    );
};

export default EventSignUp;
