import React from "react";
import "./AsideBar.css";
import { Link } from "react-router-dom";
import apiHelper from "../../utils/apiHelpers";
import { useState, useEffect } from "react";

const AsideBar = () => {
    const [user, setUser] = useState({
        name: "",
    });
    const [collapsed, setCollapsed] = useState(true);
    const [background, setBackground] = useState(false);

    useEffect(() => {
        apiHelper
            .getCurrentUser()
            .then((response) => {
                setUser(response.data);
            })

            .catch((e) => {
                console.log(e);
            });
    }, []);

    const onClickExpand = () => {
        if (collapsed) {
            setCollapsed(false);
            setBackground(true);
        } else {
            setCollapsed(true);
            setBackground(false);
        }
    };

    const clickCollapse = () => {
        if (collapsed === false) {
            setCollapsed(true);
        }
    };

    let users = null;
    let usersItem = null;
    let role = "";
    let eventSignUp = (
        <>
            <i className="fas fa-file-signature fa-sm fa-fw mr-2 text-oceandrive"></i>{" "}
            Inscripciones
            <span className="tooltip">Inscripciones</span>
        </>
    );

    if (apiHelper.isAdmin()) {
        role = "Administrador";
        usersItem = (
            <li onClick={clickCollapse}>
                <Link to={`${process.env.PUBLIC_URL}/users`}>
                    <i className="fas fa-users fa-sm fa-fw mr-2 text-oceandrive"></i>{" "}
                        Usuarios
                    <span className="tooltip">Usuarios</span>
                </Link>
            </li>
        );
    } else if (apiHelper.isMedicalCoordinator()) {
        role = "Coordinador Médico";
        usersItem = (
            <>
                <li onClick={clickCollapse}>
                    <Link to={`${process.env.PUBLIC_URL}/users#Medical_Professional`}>
                        <i className="fas fa-user-md fa-sm fa-fw mr-2 text-oceandrive"></i>{" "}
                            Profesionales
                        <span className="tooltip">Profesionales</span>
                    </Link>
                </li>
                <li onClick={clickCollapse}>
                    <Link to={`${process.env.PUBLIC_URL}/users#Attendee`}>
                        <i className="fas fa-users fa-sm fa-fw mr-2 text-oceandrive"></i>{" "}
                            Pacientes
                        <span className="tooltip">Pacientes</span>
                    </Link>
                </li>
            </>
        );

    } else if (apiHelper.isCoordinator()) {
        role = "Coordinador";
        usersItem = (
            <>
                <li onClick={clickCollapse}>
                    <Link to={`${process.env.PUBLIC_URL}/users#Invitable`}>
                        <i className="fas fa-users fa-sm fa-fw mr-2 text-oceandrive"></i>{" "}
                        Participantes
                        <span className="tooltip">Participantes</span>
                    </Link>
                </li>
            </>
        );
    } else if (apiHelper.isProfessional()) {
        role = "Profesional";
    } else if (apiHelper.isMedicalProfessional()) {
        role = "Profesional Médico";
        usersItem = (
            <li onClick={clickCollapse}>
                <Link to={`${process.env.PUBLIC_URL}/users`}>
                    <i className="fas fa-user fa-sm fa-fw mr-2 text-oceandrive"></i>{" "}
                        Pacientes
                    <span className="tooltip">Pacientes</span>{" "}
                </Link>
            </li>
        );
    } else if (apiHelper.isAuditor()) {
        role = "Auditor";
        eventSignUp = <></>;
    } else {
        role = "Sin asignar";
        eventSignUp = <></>;
    }

    return (
        <>
            <div className={`sidebar ${collapsed ? "active" : ""}`}>
                <div className={collapsed ? "collapsed" : "noncol"}>
                    <div
                        className={collapsed ? "arrowLeft" : "arrowRight"}
                        onClick={onClickExpand}
                    >
                        <i class="fas fa-arrow-circle-right"></i>
                    </div>
                    <button
                        type="button"
                        collapsed={collapsed}
                        className={`btn but ${
                            !collapsed ? "activeButton" : ""
                        }`}
                        onClick={onClickExpand}
                    >
                        <img
                            src={`${
                                collapsed
                                    ? process.env.PUBLIC_URL + "/img/loader.png"
                                    : process.env.PUBLIC_URL +
                                      "/img/logotype.png"
                            }`}
                            alt="vMedicalls logo"
                            width={collapsed ? "55" : "180"}
                        />
                    </button>
                    <span className="tooltip">Clickeame</span>
                </div>
                <div className="mt-2">
                    <h5
                        className={`text-center ${
                            !collapsed ? "visible" : "hide"
                        }`}
                    >
                        {user.name}
                    </h5>
                    <p
                        className={`text-center ${
                            !collapsed ? "visible" : "hide"
                        }`}
                    >
                        {" "}
                        {role}
                    </p>
                </div>
                <hr />
                <ul className="nav-list">
                    <li onClick={clickCollapse}>
                        <Link to={`${process.env.PUBLIC_URL}/appointments`}>
                            <i className="fas fa-calendar fa-sm fa-fw mr-2 text-oceandrive"></i>{" "}
                            Agenda
                        </Link>
                        <span className="tooltip">Agenda</span>
                    </li>

                    <li onClick={clickCollapse}>
                        <Link to={`${process.env.PUBLIC_URL}/eventSignUp`}>
                            {eventSignUp}
                        </Link>
                    </li>

                    {usersItem}

                    <hr />
                    <li onClick={clickCollapse}>
                        <Link to={`${process.env.PUBLIC_URL}/profile`}>
                            <i className="fas fa-user-circle fa-sm fa-fw mr-2 text-oceandrive"></i>
                            Perfil
                        </Link>
                        <span className="tooltip">Perfil</span>
                    </li>
                    <hr />

                    <li onClick={clickCollapse}>
                        <Link to="/login" onClick={apiHelper.logout}>
                            <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-oceandrive"></i>{" "}
                            Cerrar sesión
                        </Link>
                        <span className="tooltip">Cerrar Sesión</span>
                    </li>
                </ul>
            </div>
        </>
    );
};

export default AsideBar;
