import apiHelper from "../../utils/apiHelpers";
import NavBar from "../common/NavBar";
import Footer from "../common/Footer";
import React from "react";
import SelectDates from "../common/filters/SelectDates";
import formatter from "../../utils/formatter";
import "./AppointmentsDetail.css";
import AppointmentUsers from "../common/AppointmentUsers";
import Loader from "../common/Loader";
import { withAlert } from "react-alert";
import AppointmentOrators from "../common/AppointmentOrator";
import AsideBar from "../common/AsideBar";
import moment, { RFC_2822 } from "moment";
import Swal from 'sweetalert2';
import "../common/SweetAlert.css";

class AppointmentsDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: null,
      isNew: props.match.params.id === "new",
      loading: false,
      appointmentId: props.match.params.id,
      appointment: {
        description: "",
        appointment_date: "",
        attendees: [],
        orators: [],
        signup_password: "",
      },
    };
  }

  componentDidMount() {
    if (!this.state.isNew) {
      apiHelper
        .getAppointment(this.state.appointmentId)
        .then((response) => {
          this.setState({
            appointment: response.data,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  async deleteAppointment() {
    this.setState({ loading: true });
    await apiHelper.deleteAppointment(this.state.appointmentId);
    this.setState({ loading: false });
    this.props.history.push("/appointments");
  }

  async sendAppointmentReminder() {
    this.setState({ loading: true });
    await apiHelper.getAppointmentReminder(this.state.appointmentId);
    this.setState({ loading: false });
    Swal.fire({
      position: 'center',
      backdrop:false,
      icon: 'success',
      titleText: 'Emails de recordatorio enviados',
      showConfirmButton: false,
      timer: 2000,
      width:400,
      heightAuto:false,  
      customClass: {
    
        text:'customText'
      },
   // this.props.alert.info("Emails de recordatorio enviados");
  })
}

  handleChange(event, args=null) {
    const target = event.target;
    let value = formatter.formatFromTarget(target);
    const name = target.name;
    const appointment = this.state.appointment;
    appointment[name] = value;
    
    // Deleting patients if assigned orator isn't invited
    if(name === "orators" && apiHelper.isMedicalCoordinator()) {
      let oratorsIds = this.state.appointment.orators.map(orator => {return orator.user_id});
      const newAttendees = this.state.appointment.attendees.filter(
        attendee => {
          return attendee.professional_ids.filter(proId => oratorsIds.includes(proId)).length;
        }
      )
      appointment.attendees = newAttendees;    
    }

    this.setState({
      appointment: appointment,
    });
  
  }

  startMeeting() {
    var win = window.open(this.state.appointment.start_url, "_blank");
    win.focus();
  }


  removeOratorFromAttendees(){
    const attendees= this.state.attendees;
  }

  async submitAppointment(event) {
    event.preventDefault();
    let appointmentId = this.state.appointmentId;
    this.setState({
      errorMessage: null,
    });

    let hour = moment().format("HH:mm");

    if (formatter.isSameDayOrBefore(this.state.appointment.appointment_date)) {
      if (formatter.isSameDay(this.state.appointment.appointment_date)) {
       
        if (parseInt(this.state.appointment.scheduled_start_time) - parseInt(hour) <= 0) {

          let errorMessage = "El horario seleccionado es incorrecto.";
          this.setState({
            errorMessage: errorMessage,
          });
          return;
        }
      } else {
        let errorMessage = "El dia seleccionado es anterior a la fecha actual.";
        this.setState({
          errorMessage: errorMessage,
        });
        return;
      }
    }
    let newHourError = formatter.calculateTimeNegativeDifference(this.state.appointment.scheduled_start_time, this.state.appointment.scheduled_end_time)
    
      if(newHourError !== undefined){
      let errorMessage = newHourError;
        this.setState({
          errorMessage: errorMessage,
        });
        return;
    }
    
    try {
      //If requests already exists, update it. If not, create it
      const appointment = this.state.appointment;

      if (appointment.description.length < 1) {
        let errorMessage = "Tema de la charla no puede estar vacío.";
        this.setState({
          errorMessage: errorMessage,
        });
        return;
      }

      if (appointment.signup_password.length < 6 && !(apiHelper.isProfessional() || apiHelper.isMedicalProfessional() || apiHelper.isMedicalCoordinator())) {
        let errorMessage = "Código de invitación debe tener por lo menos 6 caracteres.";
        this.setState({
          errorMessage: errorMessage,
        });
        return;
      }

      let uniqueAttendees = appointment.attendees.filter(
        (elem, index, self) => index === self.findIndex((t) => t.user_id === elem.user_id)
      );

      let uniqueOrators = appointment.orators.filter((elem, index, self) => index === self.findIndex((t) => t.user_id === elem.user_id));

      if (uniqueAttendees.length != appointment.attendees.length || uniqueOrators.length != appointment.orators.length) {
        let errorMessage = "El evento no debe contener invitados duplicados";
        this.setState({
          errorMessage: errorMessage,
        });
        return;
      }

      if (apiHelper.isCoordinator() && appointment.orators.length < 1) {
        let errorMessage = "Debe haber 1 orador asignado al evento.";
        this.setState({
          errorMessage: errorMessage,
        });
        return;
      }
      if (apiHelper.isCoordinator() && appointment.attendees.length < 20) {
        let errorMessage = "Deben haber al menos 20 profesionales asignados al evento.";
        this.setState({
          errorMessage: errorMessage,
        });
        return;
      } else if ((apiHelper.isProfessional() || apiHelper.isMedicalProfessional() || apiHelper.isAdmin() || apiHelper.isMedicalCoordinator()) 
                  && appointment.attendees.length < 10) {
        let errorMessage = "Deben haber al menos 10 pacientes asignados al evento.";
        this.setState({
          errorMessage: errorMessage,
        });
        return;
      }

      // Cleaning attendees array since backend will throw error if objects have unknown keys
      appointment.attendees.forEach((attendee) => {
        Object.keys(attendee).forEach((key) => (key == "user_id") || delete attendee[key]);
      });

      this.setState({ loading: true });
      let response;

      if (this.state.isNew) {
        response = await apiHelper.postAppointment(appointment);
      } else {
        response = await apiHelper.patchAppointment(appointmentId, appointment);
      }
      this.setState({ loading: false });
      
      Swal.fire({
        position: 'center',
        backdrop:false,
        icon: 'success',
        titleText: 'Evento creado con exito!',
        showConfirmButton: false,
        timer: 2000,
        width:400,
        heightAuto:false,  
        customClass: {
      
          text:'customText'
        },
      })
      if (response && response.data && response.data.sent_mails) {
        Swal.fire({
          position: 'center',
          backdrop:false,
          icon: 'success',
          titleText: `${"Se enviaron " + response.data.sent_mails + " emails"}`,
          showConfirmButton: false,
          timer: 2000,
          width:400,
          customClass: {
           
            titleText:'customText'
          },

        })
        //this.props.alert.info("Se enviaron " + response.data.sent_mails + " emails");
      }

    
      this.props.history.push("/appointments");
    } catch (error) {
      this.setState({ loading: false });
      const statusCode = error.response.status;
      let errorMessage;
      if (statusCode === 422) {
        errorMessage = "Datos invalidos o incompletos";
      } else {
        errorMessage = error.response.data.error.message;
      }
      this.setState({
        errorMessage: errorMessage,
      });
    }
  }


  componentWillUnmount() {
    this._isMounted = false;
    this.deleteAppointment.bind(this);
    this.submitAppointment.bind(this);

  }

  render() {
    let title = "Crear evento";
    let deleteButton = false;
    let linkRow = false;
    let disabled = apiHelper.isAuditor() || false;
    let save = false;
    let callDuration = null;
    let alert = null;
    let selectOrator = false;
    let attendeesLabel = "Lista de invitados";
    let invitationCodeInput = <></>;


    if (!this.state.isNew) {
      title = this.state.appointment.description;

      if(!!this.state.appointment.signup_password) {
        invitationCodeInput = (
          <div className="row justify-content-center align-middle">
            <div className="col-md-11">
              <div className="form-group">
                <label htmlFor="description"> Código de invitación </label>
                <input
                  type="text"
                  name="signup_password"
                  id="signup_password"
                  className="form-control"
                  placeholder="Establezca código de invitación..."
                  value={this.state.appointment.signup_password}
                  onChange={this.handleChange.bind(this)}
                  disabled={disabled}
                  required
                  maxLength="30"
                />
              </div>
            </div>
          </div>
        );
      }

      linkRow = (
        
        <div className="row justify-content-center align-middle">
          <div className="col-md-3">
            <div className="form-group">
              <label htmlFor="join_url"> Link para invitados </label>
              <input
                type="text"
                name="join_url"
                id="join_url"
                className="form-control"
                placeholder=""
                value={this.state.appointment.join_url}
                onChange={this.handleChange.bind(this)}
                disabled
              />
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <label htmlFor="join_url"> Contraseña </label>
              <input
                type="text"
                name="meeting_password"
                id="meeting_password"
                className="form-control"
                placeholder=""
                value={this.state.appointment.meeting_password}
                onChange={this.handleChange.bind(this)}
                disabled
              />
            </div>
          </div>
          <div className="col-md-2">
            <button
              className="btn btn-warning btn-meeting"
              onClick={() => {
                navigator.clipboard.writeText(this.state.appointment.join_url);
                
                Swal.fire({
                  position: 'center',
                  backdrop:false,
                  icon: 'success',
                  titleText: 'Link copiado con éxito',
                  showConfirmButton: false,
                  timer: 2000,
                  width:400,
                  heightAuto:false,  
                  customClass: {
                
                    text:'customText'
                  },
               
              })
                

                //this.props.alert.info("Link copìado con éxito");
              }}
              type="submit"
            >
              Copiar link <i class="far fa-copy"></i>
            </button>
          </div>
          <div className="col-md-2">
            <button className="btn btn-info btn-meeting" onClick={this.startMeeting.bind(this)} type="submit" disabled={disabled} hidden={disabled}>
              Iniciar reunión <i class="far fa-play-circle"></i>
            </button>
          </div>
          <div className="col-md-2">
            <button className="btn btn-primary btn-meeting" onClick={this.sendAppointmentReminder.bind(this)} type="submit" disabled={disabled} hidden={disabled}>
              Recordatorio <i class="far fa-paper-plane"></i>
            </button>
          </div>
        </div>
        
      );

      if (apiHelper.isAdmin() || apiHelper.getUserId() === this.state.appointment.host_id) {
        deleteButton = (
          <button
            className="btn btn-dark pull-right"
            type="button"
            onClick={this.deleteAppointment.bind(this)}
            disabled={this.state.loading}
          >
            Eliminar <span className="fa fa-trash-alt"></span>
          </button>
        );
        save = (
          <div className="text-center">
            <button
              className="btn btn-primary mt-3 mb-3"
              onClick={this.submitAppointment.bind(this)}
              disabled={this.state.loading}
              type="submit"
            >
              Guardar <i className="fas fa-save"></i>
            </button>
          </div>
        );
      } else {
        disabled = true;
      }
    } else {
      // New Appointment      
      save = (
        <div className="text-center">
          <button
            className="btn btn-primary mt-3 mb-3"
            onClick={this.submitAppointment.bind(this)}
            disabled={this.state.loading}
            type="submit"
          >
            Guardar <i className="fas fa-save"></i>
          </button>
        </div>
      );
    }

    if(!!this.state.appointment.signup_password || (!apiHelper.isProfessional() && !apiHelper.isMedicalProfessional() && !apiHelper.isMedicalCoordinator() && !apiHelper.isAuditor())) {
      invitationCodeInput = (
        <div className="row justify-content-center align-middle">
          <div className="col-md-11">
            <div className="form-group">
              <label htmlFor="description"> Código de invitación </label>
              <input
                type="text"
                name="signup_password"
                id="signup_password"
                className="form-control"
                placeholder="Establezca código de invitación..."
                value={this.state.appointment.signup_password}
                onChange={this.handleChange.bind(this)}
                disabled={disabled}
                required
                maxLength="30"
              />
            </div>
          </div>
        </div>
      );
    }

    if (apiHelper.isCoordinator() || apiHelper.isAdmin() || apiHelper.isMedicalCoordinator()) {
      if (!apiHelper.isMedicalCoordinator()) {
        attendeesLabel = "Lista de Profesionales";
      }
      selectOrator = (
        <div className=" col-11 form-group">
          <label>Orador </label>
          <AppointmentOrators
            onChange={this.handleChange.bind(this)}
            name="orators"
            value={this.state.appointment.orators || []}
            placeholder="Orador"
          />
        </div>
      );
    }

    if (parseInt(this.state.appointment.scheduled_end_time) - parseInt(this.state.appointment.scheduled_start_time) >= 0) {
      callDuration =(
        <p>{formatter.calculateTimeDifference(this.state.appointment.scheduled_start_time, this.state.appointment.scheduled_end_time)}</p>
      );
    }
  

    if (this.state.errorMessage) {
      alert = <div className="alert alert-danger mt-3"> <i class="fas fa-exclamation-triangle"></i>  {this.state.errorMessage}</div>;
    }

    return (
      <div key={this.state.appointment.id} className="d-flex flex-column h-100">
        <div className="container">
          {this.state.loading == true ? (
            <Loader />
          ) : (
            <>
              <h3 className="text-oceandrive mb-1">
                <i className="fa fa-calendar"></i> {title}
                {deleteButton}
              </h3>

              <div className="row justify-content-center mt-3">
                <div className="col-md-5">
                  <div className="form-group">
                    <label htmlFor="description"> Tema de la charla </label>
                    <input
                      type="text"
                      name="description"
                      id="description"
                      className="form-control"
                      placeholder="Escriba el tema..."
                      value={this.state.appointment.description}
                      onChange={this.handleChange.bind(this)}
                      disabled={disabled}
                      required
                      maxLength="50"
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <label htmlFor="appointment_date">Fecha</label>
                    <SelectDates
                      name="appointment_date"
                      value={this.state.appointment.appointment_date}
                      onChange={this.handleChange.bind(this)}
                      isDisabled={disabled}
                    />
                  </div>
                </div>

                <div className="col-md-2 ">
                  <div className="form-group">
                    <label htmlFor="scheduled_start_time">H. de inicio</label>
                    <input
                      type="time"
                      name="scheduled_start_time"
                      className="form-control"
                      placeholder="No definido"
                      value={this.state.appointment.scheduled_start_time}
                      onChange={this.handleChange.bind(this)}
                      disabled={disabled}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-2 ">
                  <div className="form-group">
                    <label htmlFor="scheduled_end_time">H. de finalización</label>
                    <input
                      type="time"
                      name="scheduled_end_time"
                      className="form-control"
                      placeholder="No definido"
                      value={this.state.appointment.scheduled_end_time}
                      onChange={this.handleChange.bind(this)}
                      disabled={disabled}
                      required
                    />
                   <div className="ml-3">{callDuration}</div> 
                  </div>
                </div>
              </div>

              {invitationCodeInput}

              {linkRow}
              <div className="row justify-content-center mt-3">
                {selectOrator}
                <div className=" col-11 form-group">
                  <label> {attendeesLabel} </label>
                  <AppointmentUsers 
                    onChange={this.handleChange.bind(this)} 
                    name="attendees" 
                    value={this.state.appointment.attendees} 
                    orators={this.state.appointment.orators} 
                    appointmentId={this.state.appointmentId}
                    appointment={this.state.appointment}
                  />
                </div>
                {alert}
              </div>
              {save}
            </>
          )}
        </div>
        <Footer />
      </div>
    );
  }
}

export default withAlert()(AppointmentsDetail);
