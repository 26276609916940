import React, { useState, useEffect, useRef } from 'react';
import apiHelpers from '../../../utils/apiHelpers';
import './ScreenshotAttachment.css';
import Swal from 'sweetalert2';

const ScreenshotAttachment = props => {
  const { appointmentId } = props;
  const [attachments, setAttachments] = useState([]);
  const [screenshotFilename, setScreenshotFilename] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const inputFileRef = useRef();

  const refreshAttachmentList = appointmentId => {
    apiHelpers.getAppointmentScreenshotFilename(appointmentId).then(resp => {
      setScreenshotFilename(resp.data);
    });
  };

  useEffect(() => {
    refreshAttachmentList(appointmentId);
  }, [appointmentId]);

  const onUploadClick = () => {
    /*Collecting node-element and performing click*/
    inputFileRef.current.click();
  };

  // On file select (from the pop up)
  const onFileChange = event => {
    onFileUpload(event.target.files[0]);
  };

  // On file upload (click the upload button)
  const onFileUpload = selectedFile => {
    setIsUploading(true);

    // Request made to the backend api
    apiHelpers
      .uploadAppointmentScreenshot(appointmentId, selectedFile)
      .then(response => {
        if (response.status === 200) {
          refreshAttachmentList(appointmentId);
          Swal.fire({
            position: 'center',
            backdrop:false,
            icon: 'success',
            titleText: 'Archivo subido exitosamente.',
            showConfirmButton: false,
            timer: 2000,
            width:400,
            heightAuto:false,
          })
        }
      })
      .finally(() => {
        setIsUploading(false);
        refreshAttachmentList(appointmentId);
      })
      .catch(e => {
        setIsUploading(false);
        Swal.fire(`Error al subir adjunto.`, '', 'error')
      });
  };

  const onDownloadClick = async index => {
    let svResponse = await apiHelpers.downloadAppointmentScreenshot(appointmentId);

    const url = window.URL.createObjectURL(new Blob([svResponse.data]));
    const link = document.createElement('a');
    link.href = url;
    if (typeof window.navigator.msSaveBlob === 'function') {
      window.navigator.msSaveBlob(svResponse.data, screenshotFilename);
    } else {
      link.setAttribute('download', screenshotFilename);
      document.body.appendChild(link);
      link.click();
    }
  };

  const onDeleteClick = async index => {
    Swal.fire({
      title: `La imagen se eliminará permanentemente.`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Si, eliminar.',
    }).then(result => {
      if (result.isConfirmed) {
        apiHelpers.deleteAppointmentScreenshot(appointmentId)
        .then(resp => {
          Swal.fire(`La captura de pantalla fue eliminada.`, '', 'success');
          setScreenshotFilename(resp.data);
        })
        .catch(error =>  Swal.fire(`Error al eliminar adjunto.`, '', 'error'));       
      }
    });
  };

  return (
    <>            
      <div className="pull-left mr-5 mt-2">
        <input
          onChange={onFileChange}
          className="form-control form-control-lg"
          id="formFileLg"
          type="file"
          ref={inputFileRef}
          style={{ display: 'none' }}
        />
        <div>
          {(appointmentId !== "new" && !screenshotFilename) ? 
          <button 
          onClick={onUploadClick}
          className="col btn btn-warning btn-attachment mx-1">
            Adjuntar captura
          </button> : <></>}
          
          {screenshotFilename ?
          <>
            <button 
            onClick={onDownloadClick}
            className="col btn btn-success btn-dl mx-1">
              <i className="fas fa-arrow-down mr-2"></i>Descargar
            </button> 
            <button
            onClick={onDeleteClick}
            className="col btn btn-danger btn-attachment mx-1">
              Eliminar captura
            </button>          
          </>: <></>}
        </div>
        <div className="pull-left ml-2">
          <p className="filename-text">{screenshotFilename}</p>
        </div>
      </div>
                    
    </>
  );
};

export default ScreenshotAttachment;
