import React from "react";
import apiHelper from '../../../utils/apiHelpers';
import AsyncSelect from 'react-select/async';
import PropTypes from "prop-types";

const PATIENT_NAME_LENGTH = 2;

export default class SelectAttendees extends React.Component {

    constructor(props) {
        super(props);
        this.wto = null;

        this.state = {
            options: [],
        };
   

        let userId=apiHelper.getUserId();
     
        
        this.getOptions = (input, callback) => {
            
            // if (!input || input.length == 0) return;
            clearTimeout(this.wto);
            this.wto = setTimeout(() => {
                let filter = [
                    {name: {like: `%${input}%`}},
                ];
                let excludedUserIds = [userId,]

                if (props.orators && apiHelper.isCoordinator()) {
                    // Orators wont show as attendees
                    excludedUserIds = excludedUserIds.concat(props.orators.map(orator => orator.user_id));
                 }

                filter.push({id:{nin: excludedUserIds}});
                
                let filters = {
                    filter: {
                        where: {
                            and: filter
                        },
                        limit: 10,
                        
                    }
                };
                
                if (apiHelper.isAdmin()) {
                    filters = {
                        filter: {
                            where: {
                                and: 
                                    filter
                            },
                            limit: 10,
                            
                        }
                    };
                }
                
                if (apiHelper.isCoordinator()) {
                    filters = {
                      filter: {
                        where: {
                          roles: ["PROFESSIONAL", "MEDICAL_PROFESSIONAL"],
                          and: [...filter,  {invitable: true}],
                        },
                        limit: 10,
                      },
                    };
                  }

                  let professional_ids = props.orators ? props.orators.map(orator => orator.user_id) : [];                
                  if(professional_ids){
                    filters.filter.where["professionals"] = professional_ids;
                  }
                
                apiHelper.getUsers(filters).then((users) => {
                    
                    let usersForSelect = users.data.map((user) => {

                        let theId = user.id.toString();
                        let numberId = null

                        if (theId.length === 1 || this.state.isNew) {
                        numberId = "000";
                        } else if (theId.length === 2) {
                        numberId = "00";
                        } else if (theId.length === 3) {
                        numberId = "0";
                        }

                        const professional_ids = user.professionals.map(usrPro => usrPro.professional_id);

                        if(user.name.length <= PATIENT_NAME_LENGTH){
                            return {
                                value: {user_id: user.id, professional_ids: professional_ids},
                                label: numberId + user.id + " " + user.name.toUpperCase()
                            };
                        }else{
                            return {
                                value: {user_id: user.id, professional_ids: professional_ids},
                                label: user.name
                            };
                        }

                       
                    }).filter(option=>!props.selected.includes(option.value.user_id));


                    if(apiHelper.isCoordinator() && this.props.orators != undefined){
                        usersForSelect=usersForSelect.filter( option => this.props.orators[0].user_id !== option.value.user_id)
                    }

                    this.setState({
                        options: usersForSelect,
                    });
                    
                    callback(usersForSelect);
                }).catch(function (err) {
                    callback(err, null);
                });
            }, 1000);
        }
    }
   

  

    onChangeWrapper(selectedValue) {
        let event = {target: {}};
        event.target.name = this.props.name ? this.props.name : 'select-client-nameless';
        event.target.value = selectedValue ? selectedValue.value : '';
        event.target.type = 'react-select';
        this.props.onChange(event);
    }

    getDefaultOptions(id) {
        if (!id) return;

        let filters = {
            filter: {
                where: {
                    and: [
                        {
                            id: id
                        }
                    ]
                },
                
            }
        };

        apiHelper.getUsers(filters).then((users) => {
           
            let usersForSelect = users.data.map((user) => {
                let theId = user.id.toString();
                        let numberId = null

                        if (theId.length === 1 || this.state.isNew) {
                        numberId = "000";
                        } else if (theId.length === 2) {
                        numberId = "00";
                        } else if (theId.length === 3) {
                        numberId = "0";
                        }

                        const professional_ids = user.professionals.map(usrPro => usrPro.professional_id);

                        if(user.name.length <= PATIENT_NAME_LENGTH){
                            return {
                                value: {user_id: user.id, professional_ids: professional_ids},
                                label: numberId + user.id + " " + user.name.toUpperCase()
                            };
                        }else{
                            return {
                                value: {user_id: user.id, professional_ids: professional_ids},
                                label: user.name
                            };
                        }
                
                
            });
           

            if(apiHelper.isCoordinator() && this.props.orators.length > 0){
                usersForSelect=usersForSelect.filter( option => this.props.orators[0].user_id !== option.value)
            }

            this.setState({
                options: usersForSelect
            });
           
        }).catch((err) => {
            console.log(err);
        });
    }
    
    render() {
        let {value} = this.props;
        let userValue;
        let placeHolderSelect;

        if(value) {
            userValue = this.state.options.find(option => option.value.user_id === value);
            if (!userValue) this.getDefaultOptions(value);
        }

        if(apiHelper.isCoordinator()){
            placeHolderSelect="Seleccione Profesional";
        }
        if(apiHelper.isProfessional() || apiHelper.isMedicalProfessional()){
            placeHolderSelect="Id Paciente";
            
        }
        if(apiHelper.isAdmin()){
            placeHolderSelect="Seleccione Asistente";
        }


        return (
            <AsyncSelect
                className={this.props.className}
                name={this.props.name}
                onChange={this.onChangeWrapper.bind(this)}
                value={ userValue || ''} //Workaround to clear value with null. See https://github.com/JedWatson/react-select/issues/3066
                required={this.props.required}
                loadOptions={this.getOptions}
                placeholder={placeHolderSelect} 
                menuContainerStyle={{'zIndex': 999}}
                noOptionsMessage={() => "Ingrese un valor para buscar"}
                isClearable={this.props.required}
                defaultOptions={true}
                isDisabled={this.props.disabled}
            />
        )
    }
}
SelectAttendees.propTypes = {
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.number,
    isClearable: PropTypes.bool,
    orator:PropTypes.string,
    disabled:PropTypes.bool,
};