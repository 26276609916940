import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import PrivateRoute from "../../utils/PrivateRoute";
import Login from "../login/Login";
import RecoverPassword from "../login/RecoverPassword";
import AppointmentsDetail from "../appointments/AppointmentsDetail";
import Appointments from "../appointments/Appointments";
import UsersDetail from "../users/UsersDetail";
import Users from "../users/Users";
import Profile from "../profile/Profile";
import AsideBar from "./../common/AsideBar";
import EventSignUp from "../eventSignUp/EventSignUp";

const history = createBrowserHistory();

function App() {
    return (
        <>
            <Router history={history}>
                <Switch>
                    <Route name="login" path={`/login`} component={Login} />
                    <Route
                        name="recover_password"
                        path={`/recover_password`}
                        component={RecoverPassword}
                    />
                    <Route
                        name="eventSignUp"
                        path={`/eventSignUp`}
                        component={EventSignUp}
                    />

                    <PrivateRoute
                        name="editAppointment"
                        path={`/appointments/:id`}
                        component={AppointmentsDetail}
                    />
                    <PrivateRoute
                        name="appointments"
                        exact
                        key="appointments"
                        path={`/appointments`}
                        component={Appointments}
                    />
                    <PrivateRoute
                        name="editUser"
                        path={`/users/:id`}
                        component={UsersDetail}
                        allowAuditor={false}
                    />
                    <PrivateRoute
                        name="users"
                        path={`/users`}
                        component={Users}
                        allowAuditor={false}
                    />
                    <PrivateRoute
                        name="profile"
                        path={`/profile`}
                        component={Profile}
                    />
                    <PrivateRoute
                        name="home"
                        path={`/`}
                        component={Appointments}
                    />
                </Switch>
            </Router>
        </>
    );
}

export default App;
