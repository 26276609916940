// React PDF style object.
export const react_pdf_styles = {
  // Page styles
  page: {
    backgroundColor: '#FFFFFF',
    border: 0.6,
    borderColor: '#FFFFFF',
    padding: 6,
  },

  //  ++++ Header ++++
  header: {
    flexDirection: 'column',
    marginTop: 0.5,
  },
  headerLogo: {
    width: '120px',
    marginTop: 3,
    padding: 1,
    alignSelf: 'center',
    alignContent: 'center',
  },
  headerBox: {
    width: '100%',
    padding: 6,
    fontSize: 11,
    borderColor: '#000000',
    borderBottom: 1,
    alignSelf: 'center',
    alignContent: 'center',
  },
  headerBoxTop: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 0.5,
  },
  headerText: {
    paddingTop: '20px',
    fontWeight: 'bold',
    fontSize: '13px',
  },
  headerBoxBottom: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  //  ++++ General styles ++++
  section: {
    margin: 10,
    padding: 5,
    //flexGrow: 1,
  },
  sectionTitle: {
    color: '#000000',
    fontWeight: 'bold',
    paddingVertical: 2,
    marginTop: 7,
    marginBottom: 2,
    fontWeight: 'bold',
    borderBottom: 0.5,
  },

  //  ++++ AppointmentData ++++
  patientSection: {
    borderColor: '#000000',
    border: 0.5,
    borderRadius: 5,
    marginTop: 6,
    width: '95%',
    margin: 10,
    padding: 5,
  },
  appointmentDataTitle: {
    marginVertical: 2,
    color: '#444',
    fontSize: '14px',
  },
  appointmentData: {
    marginVertical: 2,
    color: 'black',
    fontSize: '16px',
  },

  meeting_url: {
    color: 'blue',
    textDecoration: 'underline',
    fontSize: '10px',
  },

  //  ++++ Result Section ++++
  examTitle: {
    paddingVertical: 2,
    marginTop: 7,
    marginBottom: 2,
    fontWeight: 'bold',
    borderBottom: 0.3,
  },
  resultRow: {
    flexDirection: 'row',
    marginVertical: 3,
    alignItems: 'center',
  },
  nameColumn: {
    // flex: 1,
    flexGrow: 3,
    flexShrink: 1,
    flexBasis: '25em',
  },
  percentileColumn: {
    // flex: 1,
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: '25em',
    display: 'flex',
    alignSelf: 'center',
  },
  scoreColumn: {
    // flex: 1,
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: '5em',
    display: 'flex',
    alignSelf: 'center',
  },
  referenceColumn: {
    // flex: 1,
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: '25em',
    display: 'flex',
    alignSelf: 'center',
  },
  tableFirstRowText: {
    fontSize: '14px',
  },
  tableText: {
    border: '1px',
    borderRadius: '3',
    borderColor: '#CCC',
    fontSize: '12px',
    padding: '3 0 3 10',
    marginBottom: '3px',
  },

  textRight: {
    textAlign: 'right',
  },

  //  ++++ Conclusions ++++
  conclusion: {
    marginTop: 10,
    padding: 5,
    borderColor: 'black',
    borderBottom: 0.3,
    borderStyle: 'dotted',
  },
  conclusionTitle: {
    color: 'black',
    fontWeight: 'bold',
  },
  conclusionBody: {
    fontSize: '11px',
  },

  //  ++++ Footer ++++
  footer: {
    position: 'absolute',
    bottom: 10,
    left: 0,
    right: 0,
  },
  footerBox: {    
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderTop: 1,
    borderColor: '#CCC'
  },
  pageNumber: {
    fontSize: 12,
    paddingRight: 60,
    paddingTop: 10,
    textAlign: 'center',
    color: 'grey',
  },
  footerLogo: {
    height: '40px',
    paddingLeft: 25,
  },
};
