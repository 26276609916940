import React from 'react';
import PropTypes from 'prop-types';
import SelectOrators from '../common/filters/SelectOrators';
import apiHelpers from '../../utils/apiHelpers';

const PROFESSIONALS_MAX_COUNT = 2;

function UserProfessionalSelect(props) {

    return (
        <div className="appointment-user-row mt-2">
            <div className="">
                <SelectOrators name="professional_id" placeholder="Seleccione profesional médico"
                                 value={props.item.professional_id || null}
                                 addedValues={props.items}
                                 onChange={props.onUpdate}
                                 errorMessage={console.log()}
                                 required={true}
                                 disabled={props.disabled}
                                 />
            </div>
            <div className="">
                <button className="btn btn-minus" type="button" onClick={props.onRemove} disabled={props.disabled}>
                    <i className="fas fa-minus"></i></button>
            </div>
        </div>
    );

}

export default class UserProfessionals extends React.Component {

    /*componentDidUpdate(prevProps) {
        if (prevProps.value != this.props.value) {
        }
    }*/

    createNewRow() {
        return {professional_id: null};
    }

    handleAdd(index, event) {
       
        const userProfessionals = this.props.value.slice(); // props.value?
        userProfessionals.push(this.createNewRow());
        this.updateOnChange(userProfessionals);
    }

    handleRemove(index, event) {
        const userProfessionals = this.props.value.slice();
        const deletedOrator = userProfessionals.splice(index, 1)[0];

        this.updateOnChange(userProfessionals);
    }

    handleUpdate(index, event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let userProfessionals = this.props.value.slice();
        userProfessionals[index][name] = value;

        this.updateOnChange(userProfessionals);
    }

    updateOnChange(userProfessionals, args=null) {
        if (this.props.onChange) {
            let event = {target: {}};
            event.target.name = this.props.name || 'appointmentUsers-nameless';
            event.target.value = userProfessionals.slice();
            event.target.type = 'elig-appointmentUsers';
            this.props.onChange(event, args);
        }
    }

    render() {
        const items = this.props.value || [];
        const selected= items.map(i=>i.professional_id);
        const isAuditor = apiHelpers.isAuditor(); 
        
        let btnAdd=null;
        btnAdd=(    
        <div className="text-center">
            <button className="btn btn-success appointment-user-add" type="button"
                    onClick={this.handleAdd.bind(this)}>
                <i className="fas fa-plus"></i>
            </button>
        </div>);

        return (
            <div>
                <div>
                    {items.map((userProfessional, index) => {
                        let isFirst = index === 0;

                        return (
                            <UserProfessionalSelect
                                item={userProfessional}
                                items={items}
                                key={index}
                                index={index}
                                isFirst={isFirst}
                                onRemove={this.handleRemove.bind(this, index)}
                                onUpdate={this.handleUpdate.bind(this, index)}
                                disabled={isAuditor}
                            />
                        )
                    })}
                </div>
                { items.length < PROFESSIONALS_MAX_COUNT && !isAuditor ? btnAdd : null}
            
            </div>

        )
    }
}


UserProfessionals.propTypes = {
    value: PropTypes.array,
    onChange: PropTypes.func,
    name: PropTypes.string
};