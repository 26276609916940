import moment from "moment";

export default {

    formatDateForAPI: (date) => {
        return moment(date).toISOString(); //.format('YYYY-MM-DDTHH:mm:ss.sss');
    },

    formatNextDayForAPI: (date) => {
        return moment(date).add(1, 'days').toISOString(); //.format('YYYY-MM-DDTHH:mm:ss.sss');
    },

    dateStringToDate: (dateString) => {
        return moment(dateString).toDate();
    },

    formatDate: (date) => {
        return moment(date).format('DD/MM/YYYY');
    },

    formatPaymentDate: (date) => {
        return moment(date).format('YYYY-MM-DD');
    },

    formatDateTime: (date) => {
        return moment(date).format('DD/MM/YYYY HH:mm:ss');
    },


    formatFromTarget: (target) => {
        let value;
        switch (target.type) {
            case 'checkbox':
                value = target.checked;
                break;
            case 'number' :
                value = Number(target.value);
                break;
            case 'react-datetime':
                value = moment(target.value).toISOString();
                break;
            default :
                value = target.value;
        }
        return value;
    },


    calculateTimeDifference: (startTime, endTime) => {

        let timeStart = new Date(`Mon Jan 01 2007 ${startTime}:00 GMT+0530`).getTime();
        let timeEnd = new Date(`Mon Jan 01 2007 ${endTime}:00 GMT+0530`).getTime();

        let hourDiff = timeEnd - timeStart;

        let minDiff = (hourDiff / 60 / 1000) % 60; //in minutes
        let hDiff = parseInt(hourDiff / 3600 / 1000); //in hours

        if (hDiff === 0) {
            return `${minDiff} minutos`
        } else if (minDiff === 0) {
            return `${hDiff} horas`
        } else {
            return `${hDiff} horas y ${minDiff} minutos`
        }
       
    },

    calculateTimeNegativeDifference: (startTime, endTime) => {

        let timeStart = new Date(`Mon Jan 01 2007 ${startTime}:00 GMT+0530`).getTime();
        let timeEnd = new Date(`Mon Jan 01 2007 ${endTime}:00 GMT+0530`).getTime();

        let hourDiff = timeEnd - timeStart;
      
        
        let minDiff = (hourDiff / 60 / 1000) % 60; //in minutes
        
        if (hourDiff < 3600000){
            return ` El evento no puede durar menos de una hora.`
        };
   
    },
    

    isSameDayOrAfter: (date) => {
        let appointmentDate = moment(date).hours(1).minutes(0).seconds(0);
        let currentDate = moment().hours(0).minutes(0).seconds(0);
        return appointmentDate.isSameOrAfter(currentDate)
    },

    isSameDayOrBefore: (date) => {
        let appointmentDate = moment(date).hours(0).minutes(0).seconds(0);
        let currentDate = moment().hours(0).minutes(0).seconds(0);
        return appointmentDate.isSameOrBefore(currentDate);
    },

    isSameDay: (date) => {
        let appointmentDate = moment(date).hours(0).minutes(0).seconds(0);
        let currentDate = moment().hours(0).minutes(0).seconds(0);
        return appointmentDate.isSame(currentDate,"day");
    },


};
