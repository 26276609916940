import React from "react";
import apiHelper from '../../../utils/apiHelpers';
import AsyncSelect from 'react-select/async';
import PropTypes from "prop-types";

const PATIENT_NAME_LENGTH = 2;

export default class SelectUserNames extends React.Component {

    constructor(props) {
        super(props);

        this.wto = null;

        this.state = {
            selectedOption: null
        };
        this.getOptions = (input, callback) => {

            clearTimeout(this.wto);
            this.wto = setTimeout(() => {
                let andConditions = [{
                    name: {
                        like: `%${input}%`
                    }
                }];

                // Adding only invitables for role coordinator.
                if (apiHelper.isCoordinator()) {
                    andConditions.push({invitable: true});
                }

                let filters = {
                    filter: {
                        where: {
                            roles: this.props.roleFilter,
                            and: andConditions,
                        },
                        limit: 10
                    }
                };


                apiHelper.getUsers(filters).then((users) => {
                    let usersForSelect = users.data.map((user) => {
                        if  (user.name.length <= PATIENT_NAME_LENGTH) {
    
                            let numberId=""
                            let theId = user.id.toString();
                            if (theId.length === 1 ) {
                              numberId = "000";
                            } else if (theId.length === 2) {
                              numberId = "00";
                            } else if (theId.length === 3) {
                              numberId = "0";
                            }
        
                            return {
                                value: user.name,
                                label: numberId + user.id + " " + user.name.toUpperCase()
                            };
                        } else {
    
                                return {
                                value: user.name,
                                label:user.name
                            };
                        }
                    });

                    callback(usersForSelect);
                }).catch(function (err) {
                    callback(err, null);
                });
            }, 1000);
        }
    }

    onChangeWrapper(selectedValue) {
        let event = {target: {}};
        event.target.name = this.props.name ? this.props.name : 'select-client-nameless';
        event.target.value = selectedValue.value;
        event.target.type = 'react-select';

        this.setState({
            selectedOption: selectedValue
        });

        this.props.onChange(event);
    }

    getDefaultOptions(name) {
        let filters = {
            filter: {
                where: {
                    roles: this.props.roleFilter,
                    and: [
                        {
                            name: name
                        }
                    ]
                },
                limit: 10
            }
        };

        apiHelper.getUsers(filters).then((users) => {
            let usersForSelect = users.data.map((user) => {
                
                return {
                    value: user.name,
                    label: user.name
                };
            });

            this.setState({
                selectedOption: usersForSelect[0]
            });
        }).catch((err) => {
            console.log(err);
        });
    }

    render() {
        let {value} = this.props;
        let {selectedOption} = this.state;

        if(value && !selectedOption) {
            this.getDefaultOptions(value);
        }

        if(!value) {
            selectedOption = null;
        }

        return (
            <AsyncSelect
                className={this.props.className}
                name={this.props.name}
                onChange={this.onChangeWrapper.bind(this)}
                value={selectedOption || ''} //Workaround to clear value with null. See https://github.com/JedWatson/react-select/issues/3066
                required={this.props.required}
                loadOptions={this.getOptions}
                placeholder={this.props.placeholder}
                menuContainerStyle={{'zIndex': 999}}
                noOptionsMessage={() => "Ingrese un valor para buscar"}
                isClearable={this.props.required}
                defaultOptions={true}
            />
        )
    }
}
SelectUserNames.propTypes = {
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    isClearable: PropTypes.bool,
};