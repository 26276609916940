import React from "react";
import { Redirect, Route } from "react-router-dom";
import apiHelpers from "../utils/apiHelpers";
import AsideBar from "./../components/common/AsideBar";

const PrivateRoute = ({ component: Component, ...rest }) => {
    const allowAuditor = rest.allowAuditor;
    let allowed = true;
    if (allowAuditor === false && apiHelpers.isAuditor()) {
        allowed = false;
    }

    return (
        <>
            <AsideBar />
            <Route
                {...rest}
                render={(props) =>
                    apiHelpers.isAuthenticated() === true && allowed ? (
                        <Component {...props} />
                    ) : (
                        <Redirect to="/login" />
                    )
                }
            />
        </>
    );
};

export default PrivateRoute;
