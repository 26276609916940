const Calendar = {
    sunday: 'Dom',
    monday: 'Lun',
    tuesday: 'Mar',
    wednesday: 'Mie',
    thursday: 'Jue',
    friday: 'Vie',
    saturday: 'Sab',
    ok: 'OK',
    today: 'Hoy',
    yesterday: 'Ayer',
    hours: 'Horas',
    minutes: 'Minutos',
    seconds: 'Segundos',
    /**
     * Format of the string is based on Unicode Technical Standard #35:
     * https://www.unicode.org/reports/tr35/tr35-dates.html#Date_Field_Symbol_Table
     **/
    formattedMonthPattern: 'MMM YYYY',
    formattedDayPattern: 'DD MMM YYYY'
};


export default {
    Pagination: {
        more: 'More',
        prev: 'Previous',
        next: 'Next',
        first: 'First',
        last: 'Last'
    },
    Table: {
        emptyMessage: 'No data found',
        loading: 'Loading...'
    },
    TablePagination: {
        lengthMenuInfo: '{0} / page',
        totalInfo: 'Total: {0}'
    },
    Calendar,
    DatePicker: {
        ...Calendar
    },
    DateRangePicker: {
        ...Calendar,
        last7Days: 'Last 7 Days'
    },
    Picker: {
        noResultsText: 'No results found',
        placeholder: 'Select',
        searchPlaceholder: 'Search',
        checkAll: 'All'
    },
    InputPicker: {
        newItem: 'New item',
        createOption: 'Create option "{0}"'
    },
    Uploader: {
        inited: 'Initial',
        progress: 'Uploading',
        error: 'Error',
        complete: 'Finished',
        emptyFile: 'Empty',
        upload: 'Upload'
    }
};
