const axios = require('axios');
var config = require('../config/config');

const SESSION_STORAGE_SESSION = 'session';

const SERVER_URL = config.backendUrl;
const LOGIN_URL = SERVER_URL + '/users/login';
const CHANGE_PASSWORD_URL = SERVER_URL + '/users/change-password';
const RESET_URL = SERVER_URL + '/users/reset';
const CURRENT_USER_URL = SERVER_URL + '/users/me';
const USERS_URL = SERVER_URL + '/users';
const USERS_COUNT_URL = SERVER_URL + '/users/count';
const USERS_INDEX_URL = SERVER_URL + '/users/{id}';
const USER_ROLES_URL = SERVER_URL + '/user-roles';
const USER_ROLES_COUNT_URL = SERVER_URL + '/user-roles/count';
const USER_ROLES_INDEX_URL = SERVER_URL + '/user-roles/{id}';
const USER_PROFESSIONAL_URL = SERVER_URL + '/user-professional';
const APPOINTMENTS_URL = SERVER_URL + '/appointments';
const APPOINTMENTS_PUBLIC_URL = SERVER_URL + '/appointments/public';
const APPOINTMENTS_COUNT_URL = SERVER_URL + '/appointments/count';
const APPOINTMENTS_INDEX_URL = SERVER_URL + '/appointments/{id}';
const APPOINTMENTS_REMINDER_URL = SERVER_URL + '/appointments/{id}/reminder';
const APPOINTMENTS_CHECK_EVENT_SIGNUP_PASSWORD = SERVER_URL + '/appointments/checkPassword/{id}';
const APPOINTMENTS_SIGNUP_USER = SERVER_URL + '/appointments/signup/{id}';
const APPOINTMENTS_SCREENSHOT_URL = SERVER_URL + '/appointments/{id}/screenshot';
const APPOINTMENTS_SCREENSHOT_FILENAME_URL = SERVER_URL + '/appointments/{id}/screenshotFilename';

function getBackendUrl() {
    return SERVER_URL;
}

const qs = require('qs');

function getUserId(){
    if (sessionStorage.getItem(SESSION_STORAGE_SESSION)) {
        return JSON.parse(sessionStorage.getItem(SESSION_STORAGE_SESSION)).id;
    } else {
        return false;
    }
}

function isAuthenticated() {
    return sessionStorage.getItem(SESSION_STORAGE_SESSION) !== null;
}

function isAdmin() {
    if (sessionStorage.getItem(SESSION_STORAGE_SESSION)) {
        const roles = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_SESSION)).roles;
        return roles.includes("ADMIN");
    } else {
        return false;
    }
}

function isCoordinator() {
    if (sessionStorage.getItem(SESSION_STORAGE_SESSION)) {
        const roles = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_SESSION)).roles;

        return roles.includes("COORDINATOR");
    } else {
        return false;
    }
}

function isMedicalCoordinator() {
    if (sessionStorage.getItem(SESSION_STORAGE_SESSION)) {
        const roles = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_SESSION)).roles;

        return roles.includes("MEDICAL_COORDINATOR");
    } else {
        return false;
    }
}

function isProfessional() {
    if (sessionStorage.getItem(SESSION_STORAGE_SESSION)) {
        const roles = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_SESSION)).roles;

        return roles.includes("PROFESSIONAL");
    } else {
        return false;
    }
}

function isMedicalProfessional() {
    if (sessionStorage.getItem(SESSION_STORAGE_SESSION)) {
        const roles = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_SESSION)).roles;

        return roles.includes("MEDICAL_PROFESSIONAL");
    } else {
        return false;
    }
}


function isAuditor() {
    if (sessionStorage.getItem(SESSION_STORAGE_SESSION)) {
        const roles = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_SESSION)).roles;

        return roles.includes("AUDITOR");
    } else {
        return false;
    }
}

function isAttendee() {
    if (sessionStorage.getItem(SESSION_STORAGE_SESSION)) {
        const roles = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_SESSION)).roles;

        return roles.includes("ATTENDEE");
    } else {
        return false;
    }
}

function buildHeaders() {
    if (sessionStorage.getItem(SESSION_STORAGE_SESSION)) {
        return {headers: {'Authorization': 'Bearer ' + JSON.parse(sessionStorage.getItem(SESSION_STORAGE_SESSION)).token}};
    } else {
        return null;
    }
}


function filterNulls(payload) {
    if(Array.isArray(payload)){
        return payload.filter((a)=> a !== null)
    } else {
        return Object.entries(payload).reduce((a,[k,v]) => (v === null ? a : {...a, [k]:v}), {});
    }
}

function login(payload) {
    return axios.post(LOGIN_URL, {
        username: payload.username,
        password: payload.password
    }).then(function (response) {
        sessionStorage.setItem(SESSION_STORAGE_SESSION, JSON.stringify(response.data));
        return response;
    });
}

function logout() {
    sessionStorage.clear(SESSION_STORAGE_SESSION);
}

function changePassword(payload) {
    return axios.post(CHANGE_PASSWORD_URL, {
        username: payload.username,
        password: payload.password,
        newPassword: payload.newPassword
    }, buildHeaders()).then(function (response) {
        return response;
    });
}

function resetPassword(payload) {
    return axios.post(RESET_URL, {
        username: payload.email,
    }).then(function (response) {
        return response;
    });
}

function getCurrentUser() {
    return axios.get(CURRENT_USER_URL, buildHeaders());
}


function getUsers(filters) {
    return axios.get(
        `${USERS_URL}?${qs.stringify(filters, {skipNulls: true})}`,
        buildHeaders()
    );
}

function getUsersCount(filters) {
    return axios.get(
        `${USERS_COUNT_URL}?${qs.stringify(filters, {skipNulls: true})}`,
        buildHeaders()
    );
}

function getUser(userId) {
    return axios.get(USERS_INDEX_URL.replace('{id}', userId), buildHeaders());
}

function postUser(payload) {
    return axios.post(USERS_URL, filterNulls(payload), buildHeaders());
}

function patchUser(userId, payload) {
    return axios.patch(USERS_INDEX_URL.replace('{id}', userId), filterNulls(payload), buildHeaders());
}

function deleteUser(userId) {
    return axios.delete(USERS_INDEX_URL.replace('{id}', userId), buildHeaders());
}


function getUserRoles(filters) {
    return axios.get(
        `${USER_ROLES_URL}?${qs.stringify(filters, {skipNulls: true})}`,
        buildHeaders()
    );
}

function getUserRolesCount(filters) {
    return axios.get(
        `${USER_ROLES_COUNT_URL}?${qs.stringify(filters, {skipNulls: true})}`,
        buildHeaders()
    );
}

function getUserRole(id) {
    return axios.get(USER_ROLES_INDEX_URL.replace('{id}', id), buildHeaders());
}

function postUserRole(payload) {
    return axios.post(USER_ROLES_URL, filterNulls(payload), buildHeaders());
}

function patchUserRole(id, payload) {
    return axios.patch(USER_ROLES_INDEX_URL.replace('{id}', id), filterNulls(payload), buildHeaders());
}

function deleteUserRole(id) {
    return axios.delete(USER_ROLES_INDEX_URL.replace('{id}', id), buildHeaders());
}



function getAppointments(filters) {
    return axios.get(
        `${APPOINTMENTS_URL}?${qs.stringify(filters, {skipNulls: true})}`,
        buildHeaders()
    );
}

function getPublicAppointments(filters) {
    return axios.get(
        `${APPOINTMENTS_PUBLIC_URL}?${qs.stringify(filters, {skipNulls: true})}`,
        buildHeaders()
    );
}

function getAppointmentsCount(filters) {
    return axios.get(
        `${APPOINTMENTS_COUNT_URL}?${qs.stringify(filters, {skipNulls: true})}`,
        buildHeaders()
    );
}

function getAppointment(appointmentId) {
    return axios.get(APPOINTMENTS_INDEX_URL.replace('{id}', appointmentId), buildHeaders());
}

function postAppointment(payload) {
    return axios.post(APPOINTMENTS_URL, filterNulls(payload), buildHeaders());
}

function patchAppointment(userId, payload) {
    return axios.patch(APPOINTMENTS_INDEX_URL.replace('{id}', userId), filterNulls(payload), buildHeaders());
}

function deleteAppointment(appointmentId) {
    return axios.delete(APPOINTMENTS_INDEX_URL.replace('{id}', appointmentId), buildHeaders());
}

function getAppointmentReminder(id) {
    return axios.get(APPOINTMENTS_REMINDER_URL.replace('{id}', id), buildHeaders());
}

function getExportAppointments(filters) {
    return axios.get(
        `${APPOINTMENTS_URL + "/export"}?${qs.stringify(filters, {skipNulls: true})}`,
        buildHeaders()
    );
}

function uploadAppointmentScreenshot(appointmentId, payload) {
    const data = new FormData();
    data.append('file', payload);
    let url = APPOINTMENTS_SCREENSHOT_URL.replace('{id}', appointmentId);
    return axios.post(url, data, buildHeaders());
}

function deleteAppointmentScreenshot(appointmentId) {
    let url = APPOINTMENTS_SCREENSHOT_URL.replace('{id}', appointmentId);
    return axios.delete(url, buildHeaders());
}

function getAppointmentScreenshotFilename(appointmentId) {
    let url = APPOINTMENTS_SCREENSHOT_FILENAME_URL.replace('{id}', appointmentId);
    return axios.get(url, {
    ...buildHeaders(),
    // responseType: 'blob',
  });
}

function downloadAppointmentScreenshot(appointmentId) {
    let url = APPOINTMENTS_SCREENSHOT_URL.replace('{id}', appointmentId);
    return axios.get(url, {
    ...buildHeaders(),
    responseType: 'blob',
  });
}

function checkEventSignUpPassword(id, password) {
    return axios.post(
        APPOINTMENTS_CHECK_EVENT_SIGNUP_PASSWORD.replace('{id}', id), {password}, buildHeaders()
    );
}

function postSignUpToEvent(id, user, eventPassword) {
    return axios.post(
        APPOINTMENTS_SIGNUP_USER.replace('{id}', id), {user, eventPassword: eventPassword}, buildHeaders()
    );
}

function getUserProfessionals(filters) {
    return axios.get(
        `${USER_PROFESSIONAL_URL}?${qs.stringify(filters, {skipNulls: true})}`,
        buildHeaders()
    );
}


export default {
    getUserId,
    isAuthenticated,
    isAdmin,
    isCoordinator,
    isMedicalCoordinator,
    isProfessional,
    isMedicalProfessional,
    isAuditor,
    isAttendee,
    login,
    logout,
    changePassword,
    resetPassword,
    getCurrentUser,
    getUsers,
    getUser,
    deleteUser,
    postUser,
    patchUser,
    getUsersCount,
    getUserRoles,
    getUserRole,
    deleteUserRole,
    postUserRole,
    patchUserRole,
    getUserRolesCount,
    getUserProfessionals,
    getAppointments,
    getPublicAppointments,
    getAppointment,
    deleteAppointment,
    postAppointment,
    patchAppointment,
    getAppointmentsCount,
    getAppointmentReminder,
    getExportAppointments,
    uploadAppointmentScreenshot,
    deleteAppointmentScreenshot,
    getAppointmentScreenshotFilename,
    downloadAppointmentScreenshot,
    checkEventSignUpPassword,
    postSignUpToEvent,
};