import React from "react";
import apiHelper from '../../utils/apiHelpers';

export default class ChangePasswordContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            old_password: '',
            new_password: '',
            new_password2: '',
            errorMessage: null
        };
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState(prevState => ({
            ...prevState,
            [name]: value,
        }));
    }

    async handleSubmit(event) {
        event.preventDefault();

        try {
            await apiHelper.changePassword({
                password: this.state.old_password,
                newPassword: this.state.new_password,
            });
            apiHelper.logout();
            this.props.history.push('/login');
        } catch (error) {
            const errorMessage = error.message;
            this.setState({
                errorMessage: errorMessage
            });
        }
    }

    render() {
        let alert;

        if (this.state.errorMessage) {
            alert = <div className="alert alert-warning">{this.state.errorMessage}</div>;
        }

        return (
            <div className="form-container">
                <h3 className="text-center my-3">Cambiar contraseña</h3>

                <form onSubmit={this.handleSubmit.bind(this)}>
                    <div className="row justify-content-center">
                        <div className="col-md-5">
                            <div className="form-group">
                                <label htmlFor="first_name">Contraseña Actual</label>
                                <input type="password"
                                       className="form-control" name="old_password"
                                       value={this.state.old_password} onChange={this.handleChange.bind(this)}
                                       required/>

                                <small className="form-text text-muted">
                                    Para cambiar la contraseña debe proveer la actual contraseña y repetir dos veces la
                                    nueva contraseña.
                                </small>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="form-group">
                                <label htmlFor="first_name">Nueva Contraseña</label>
                                <input type="password"
                                       className="form-control" name="new_password"
                                       value={this.state.new_password} onChange={this.handleChange.bind(this)}
                                       required/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="first_name">Repetir nueva contraseña</label>
                                <input type="password"
                                       className="form-control" name="new_password2"
                                       value={this.state.new_password2} onChange={this.handleChange.bind(this)}
                                       required/>
                            </div>
                        </div>
                    </div>

                    <div className="text-center">
                        {alert}

                        <button className="btn btn-primary" type="submit">
                            Cambiar Contraseña
                        </button>
                    </div>
                </form>
            </div>
        )
    }

}