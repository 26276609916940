import React from 'react';
import PropTypes from 'prop-types';
import SelectAttendees from './filters/SelectAttendees';
import './AppointmentUsers.css';
import apiHelper from '../../utils/apiHelpers';
import Loader from './Loader';
import ScreenshotAttachment from '../appointments/screenshotAttachment/ScreenshotAttachment';
import { generatePDFDocument } from '../../utils/exportPdf/exportPdf';

function AppointmentUsersRow(props) {

    return (
        <div className="appointment-user-row mt-2">
            <div className="">
                <SelectAttendees name="user_id"
                                 value={props.item.user_id || null}
                                 onChange={props.onUpdate}
                                 errorMessage={console.log()}
                                 required={true}
                                 selected={props.selected}
                                 orators={props.orators}
                                 disabled={props.disabled}
                                 />
            </div>
            <div className="">
                <button className="btn btn-minus" type="button" onClick={props.onRemove} disabled={props.disabled} hidden={props.disabled}>
                    <i className="fas fa-minus"></i></button>
            </div>
        </div>
    );

}

export default class AppointmentUsers extends React.Component {
    constructor(props) {
        super(props);
       
        this.state = {
            loading: false,
            users: [{
                name:props.value.name,
                user_id:props.value.user_id,
            }],
        };
    }

    handleAdd(index, event) {
        
        const appointmentUsers = this.props.value.slice();
        appointmentUsers.push({});
        this.updateOnChange(appointmentUsers);
    }


    
    handleAddAll=()=> {
        let andConditions = [];
        let oratorIds = this.props.orators.map(orator => orator.user_id);
        let professionals = null;
        let roles = null;

        if (apiHelper.isProfessional() || apiHelper.isMedicalProfessional() || apiHelper.isCoordinator()) {
            // Include self to invite assigned users
            oratorIds.push(apiHelper.getUserId());
        }

        if (oratorIds.length > 0) {
            if (apiHelper.isCoordinator()) {
                roles = ["PROFESSIONAL", "MEDICAL_PROFESSIONAL"];
            } else {
                // filter to only show patients of selected professionals (orators)
                professionals = oratorIds;
            }
        }      

        // Adding only invitables for role coordinator.
        if (apiHelper.isCoordinator()) {
            andConditions = [...andConditions, {invitable: true}];
        }

        let filters = {
            filter: {
                where: {
                    roles: roles,
                    and: andConditions,
                    professionals,
                },
            }
        };

        apiHelper.getUsers(filters)
        .then((users) => {
            this.setState({ loading: true });

            let userId=apiHelper.getUserId();
            const appointmentUsers = this.props.value.slice();

            let usersForSelect = users.data.map((user, index) => {
               return {user_id: user.id, professional_ids: user.professionals.map(usrProRel => usrProRel.professional_id)}
            })
            .filter( option => 
                option.user_id !== userId && !appointmentUsers.some(a=>a.user_id==option.user_id)
            )

            if( apiHelper.isCoordinator() && this.props.orators.length > 0 ) {
                usersForSelect = usersForSelect.filter( option => this.props.orators[0].user_id !== option.user_id)
            }

            this.updateOnChange([ ...appointmentUsers,...usersForSelect]);
            this.setState({ loading: false });

       }).catch((err) =>{
        this.setState({ loading: false });
           console.log(err);
       });
     
   }
    
   handleRemove(index, event) {
        const appointmentUsers = this.props.value.slice();
        appointmentUsers.splice(index, 1);

        this.updateOnChange(appointmentUsers);
    }

    handleRemoveAll(event) {
        this.updateOnChange([]);
    }

    handleUpdate(index, event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let appointmentUsers = this.props.value.slice();
        appointmentUsers[index] = value;

        this.updateOnChange(appointmentUsers);
    }

    updateOnChange(appointmentUsers) {
        if (this.props.onChange) {
            let event = {target: {}};
            event.target.name = this.props.name || 'appointmentUsers-nameless';
            event.target.value = appointmentUsers.slice();
            event.target.type = 'elig-appointmentUsers';
            this.props.onChange(event);
        }
    }


    render() {
        const items = this.props.value || [];       
        const selected= items.map(i=>i.user_id);
        const isAuditor = apiHelper.isAuditor();
        const btnAttendeeDisabled = (!this.props.orators.length && (!apiHelper.isProfessional() && !apiHelper.isMedicalProfessional()));     
        
        return (
            <div>
                <div>

                    {this.state.loading == true ? <Loader/> : items.map((appointmentUser, index) => {
                       
                        let isFirst = index === 0;

                        return (
                            <AppointmentUsersRow
                                item={appointmentUser}
                                key={index}
                                index={index}
                                isFirst={isFirst}
                                onRemove={this.handleRemove.bind(this, index)}
                                onUpdate={this.handleUpdate.bind(this, index)}
                                selected={selected}
                                orators={this.props.orators}
                                disabled={isAuditor}
                            />
                        )
                    })}
                </div>
                {!(isAuditor || apiHelper.isAdmin()) ? 
                    <div class="d-flex">
                        <div class="mr-auto p-2">
                            <ScreenshotAttachment
                                appointmentId={this.props.appointmentId}
                            />
                        </div>

                        <div class="ml-auto p-2">
                            <div className="pull-right mr-5 mt-2">              
                                <button 
                                    className="btn btn-success appointment-user-add some-air mx-1" 
                                    type="button"
                                    onClick={ this.handleAdd.bind(this)}
                                    disabled={btnAttendeeDisabled}
                                >
                                    <i className="fas fa-plus"></i>
                                </button>
                                <button 
                                    className="btn btn-success some-air mx-1" 
                                    type="button"
                                    onClick={this.handleAddAll.bind(this)}
                                    disabled={btnAttendeeDisabled}
                                >
                                    Invitar a Todos
                                </button>
                                {this.props.value.length > 0 ? <button className="btn btn-danger some-air ml-1" type="button"
                                        onClick={this.handleRemoveAll.bind(this)}>
                                    Borrar Todos
                                </button> : <></>}            
                            </div>
                        </div>

                    </div>
                  
                    : 
                    <>
                    <div class="d-flex">
                        <div class="mr-auto p-2">
                            <button 
                                className="btn btn-success mx-1" 
                                type="button"
                                onClick={() => {generatePDFDocument(this.props.appointment)}}
                                disabled={!this.props.appointment.screenshot_name}
                            >
                                <i className="fas fa-file-download"></i> Imprimir documento
                            </button>
                        </div>
                    </div>
                    </>
                }
                
        
            </div>
            

        )
    }
}


AppointmentUsers.propTypes = {
    value: PropTypes.array,
    onChange: PropTypes.func,
    name: PropTypes.string,
    orators:PropTypes.string,
};