import React from 'react';
import {Link} from "react-router-dom";
import './Login.css';
import apiHelper from '../../utils/apiHelpers';


class Login extends React.Component {
    constructor() {
        super();
        this.state = {
            username: '',
            password: '',
            errorMessage: null
        };
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState(prevState => ({
            ...prevState,
            [name]: value,
        }));
    }

    async handleSubmit(event) {
        event.preventDefault();

        try {
            await apiHelper.login({
                username: this.state.username,
                password: this.state.password,
            });
            this.props.history.push('/');
        } catch (error) {
            const statusCode = error.response.status;
            let errorMessage;

            if (statusCode === 401) {
                errorMessage = 'Email o contraseña invalidas';
            } else {
                errorMessage = error.message;
            }

            this.setState({
                errorMessage: errorMessage
            })
        }
    }

    render() {
        let alert;

        if (this.state.errorMessage) {
            alert = <div className="alert alert-warning">{this.state.errorMessage}</div>;
        }

        return (
            <div className="container-fluid">
                <div className="wrapper fadeInDown">

                    <form className="form-signin" onSubmit={this.handleSubmit.bind(this)}>

                        <div className="fadeIn first">
                            <img className="logo logo-login" src={process.env.PUBLIC_URL + '/img/logotype.png'} alt="logo" />
                            <p>By Elig</p>
                            

                            <h5 className=" mb-3  mt-4 font-weight-normal">Ingrese sus datos</h5>
                            <div className="form-group mb-0">
                                <label htmlFor="inputEmail" className="sr-only">Dirección email</label>
                                <input type="email" name="username" className="form-control fadeIn second"
                                       placeholder="Dirección email"
                                       value={this.state.username} onChange={this.handleChange.bind(this)} required
                                       autoFocus/>
                            </div>

                            <div className="form-group">
                                <label htmlFor="inputPassword" className="sr-only">Contraseña</label>
                                <input type="password" name="password" className="form-control fadeIn third mt-3"
                                       placeholder="Contraseña"
                                       value={this.state.password} onChange={this.handleChange.bind(this)} required/>
                            </div>
                            {alert}
                            <button className="btn btn-lg-4 btn-primary btn-block fadeIn fourth" formAction="index.html"
                                    type="submit">
                                Iniciar sesión <i className="fas fa-sign-in-alt"></i>
                            </button>
                            <hr/>
                            <Link to='/recover_password'>Olvide mi Contraseña</Link>
                        </div>

                    </form>

                </div>
            </div>
        );
    }
}

export default Login;